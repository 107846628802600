import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

import { firstValueFrom, fromEvent } from 'rxjs';
import { BANDWIDTH, RESPONSE_TIME, RESPONSES_CODES, TRAFIC } from 'app/shared/highcharts/graph/graph';
import { AuthService } from '../../services/auth.service';
import { CONTEXT, ContextEnum, JournalFilters, SitesService } from '../../services/sites.service';
import { MY_LOGS_ROUTE } from 'app/app-routing.module';
import { formatHumanReadableDate } from '../../shared/utils/data-utils';
import { DATE_NOW, RANGE_PRESETS } from 'app/shared/date-range-selector/calendar-data.service';
import { CountryNames, CountryService } from '../../services/country.service';
import _ from 'lodash';
import { FiltersEnum } from '../my-logs/filters/filters';
import { DateRange } from '../../shared/utils/date-range';
import {
  EventSeverity,
  JournalEvent,
  JournalQuery,
  OrganizationJournalService,
} from '../../services/organization-journal.service';

interface BrainDrive {
  driveUuid: string;
  uid?: string;
  label: string;
}

@Component({
  selector: 'app-journal',
  templateUrl: './journal.component.html',
  styleUrls: ['./journal.component.scss', '../../../assets/icon/icofont/css/icofont.scss'],
  standalone: false,
})
export class JournalComponent implements OnInit, OnDestroy {
  lang: string;

  @ViewChild('logsTable') logsTable;

  ctx: ContextEnum = CONTEXT;

  mapGraphTypeTrack = {
    newIpBlocked: TRAFIC,
    highBannedVolume: TRAFIC,
    increasedResponseTime: RESPONSE_TIME,
    increasedBandwidth: BANDWIDTH,
    '4XX': RESPONSES_CODES,
    '5XX': RESPONSES_CODES,
    traficSpike: TRAFIC,
    increased4xxRate: RESPONSES_CODES,
    increased5xxRate: RESPONSES_CODES,
  };

  logs: JournalEvent[] = [];
  highlighted: Set<number> = new Set<number>();

  brainDrives: { [prop: string]: BrainDrive } = {};
  isLoading: boolean = false;

  total = 0;
  size = 25;

  countries: CountryNames;

  index = 0;
  filters: JournalFilters = {
    period: { range: undefined, autoRefresh: false },
  };

  constructor(
    private journalService: OrganizationJournalService,
    private http: HttpClient,
    private auth: AuthService,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private sites: SitesService,
    private countryService: CountryService,
  ) {}

  async ngOnInit() {
    this.lang = this.auth.getCurrentLanguage();

    this.route.queryParams.subscribe((params) => {
      // If data is passed from query-string
      if (Object.keys(params).length) {
        if (params['before']) {
          this.filters.period = {
            range: new DateRange(
              moment(params['before']).subtract(30, 'seconds'),
              moment(params['before']).add(1, 'milliseconds'),
            ),
            autoRefresh: false,
          };
        }

        if (params['severity']) {
          this.filters.severity = params['severity'];
        }

        if (params['site']) {
          this.sites[this.ctx].selectSites([params['site']]);
        }

        if (params['clusterId']) {
          this.sites[this.ctx].selectClusterByClusterId(params['clusterId']);
        }

        if (params['type']) {
          this.filters.type = params['type'];
        }

        if (params['id']) {
          this.highlighted.add(Number.parseInt(params['id']));
        }
      } else {
        // else if data is stored in context from previous navigation
        if (this.sites[this.ctx].current.journalFilters) {
          this.filters = this.sites[this.ctx].current.journalFilters;
          // else default
        } else {
          this.filters.period = {
            range: RANGE_PRESETS['T'],
            autoRefresh: false,
          };
        }
      }
    });

    await this.sites.load(this.ctx);
    await this.countryService.init();
    this.countries = this.countryService.getCountryNames();
    await this.getCodesAttacks();
    await this.refresh();
  }

  ngOnDestroy() {
    this.sites[this.ctx].current.journalFilters = this.filters;
    this.highlighted.clear();
  }

  refresh() {
    console.log('refresh');
    this.index = 0;
    this.filters.period.range = this.filters.period.range.refresh();
    this.getEvents();
  }

  onPeriodEmitted(p: DateRange) {
    this.filters.period = {
      range: p,
      autoRefresh: false,
    };
    this.refresh();
  }

  toggleExpandRow(row) {
    this.logsTable.rowDetail.toggleExpandRow(row);
  }

  changePage(e: any) {
    this.index = e.page - 1;
    this.getEvents();
  }

  async getCodesAttacks() {
    this.brainDrives = _.keyBy(
      await firstValueFrom(this.http.get<BrainDrive[]>('v2/brain-drives')),
      (drive) => drive.uid,
    );
  }

  getSearchParameters(): Partial<JournalQuery> {
    return {
      after: this.filters?.period?.range?.start,
      before: this.filters.period?.range?.end,
      clusterId: this.sites[this.ctx]?.current?.cluster?.clusterId,
      sites: this.sites[this.ctx]?.current?.selectedSites.map((s) => s.name),
      tags: this.sites[this.ctx]?.current?.tag ? [this.sites[this.ctx]?.current?.tag] : null,
      severity: this.filters?.severity,
      type: this.filters?.type,
      ip: this.filters?.ip,
    };
  }

  async getEvents() {
    this.isLoading = true;
    try {
      const res = await firstValueFrom(
        this.journalService.getJournalEvents(this.getSearchParameters(), { index: this.index, size: this.size }),
      );
      this.total = res.totalElements;
      this.logs = res.content.map((log) => this.formatLog(log));
    } finally {
      this.isLoading = false;
    }
  }
  formatLog(log: any) {
    if (log.date) {
      log.outOfDate = moment().diff(log.date, 'days') > 89;
    }

    if (log.clusterId) {
      log.cluster = this.sites[this.ctx].store.clusters.find((c) => c.clusterId == log.clusterId) || '-';
    }

    if (log.event.drives) {
      log.event.drives.forEach((drive) => {
        drive.label = this.brainDrives[drive.uid]?.label || 'DRIVE_NOT_FOUND: ' + drive.uid;
        delete drive.uid;
      });
    }

    if (log.event.firstSuspicion) {
      log.event.firstSuspicion = formatHumanReadableDate(log.event.firstSuspicion, this.lang, 'long');
    }

    if (log.event.block) {
      log.event.block = formatHumanReadableDate(log.event.block, this.lang, 'long');
    }

    if (log.event.endDate) {
      log.event.endDate = formatHumanReadableDate(log.event.endDate, this.lang, 'long');
    }

    if (log.event.startDate) {
      log.event.startDate = formatHumanReadableDate(log.event.startDate, this.lang, 'long');
    }

    return log;
  }

  trackEvent(row) {
    let end = moment(row.date).add(30, 'minutes');
    const now = DATE_NOW();

    if (end.isAfter(now)) {
      end = now;
    }

    this.sites[this.ctx].current.period = {
      range: new DateRange(moment(row.date).subtract(30, 'minutes'), end),
      autoRefresh: false,
    };

    let query = {
      state: {
        graphType: this.mapGraphTypeTrack[row.subtype],
        sites: [row.site],
        clusterName: row.cluster.name,
      },
    };

    if (row.event.ip) {
      query.state[FiltersEnum.IP] = row.event.ip;
    }

    this.router.navigate([MY_LOGS_ROUTE], query);
  }

  getRowClass = (row: JournalEvent) => ({
    'row-highlight': this.highlighted.has(row.id),
  });

  protected readonly EventSeverity = EventSeverity;
}
