import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import { HostModeService } from '../../../shared/hostMode/hostMode.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { copyObject, subnet } from '../../../shared/utils/data-utils';
import { AuthService } from 'app/services/auth.service';
import { validateIp, validatePath } from 'app/shared/validators/validators';
import { OrganizationSiteService } from '../../../services/organization-site.service';
import { Rule, Site } from '../../../services/entities/site';
import { MakeOptional } from '../../../shared/utils/type-utils';
import { Audit } from '../../../services/entities/audit';
import _ from 'lodash';

@Component({
  selector: 'app-access-control-rules',
  templateUrl: './access-control-rules.component.html',
  styleUrls: [
    './access-control-rules.component.scss',
    '../../../../assets/icon/icofont/css/icofont.scss',
    '../add-site.component.scss',
    '../activable-and-ordered.scss',
  ],
  standalone: false,
})
export class AccessControlRulesComponent implements OnInit {
  lang: string;

  @Input({ required: true }) siteName: string;
  @Input({ required: true }) clusterSupportsCache = false;

  @ViewChild('accessRuleUriElement') accessRuleUriElement: ElementRef;

  site: Site;
  rules: RuleVM[] = [];
  editingRule: MakeOptional<Rule, 'id'>;
  disabled = true;
  tmpRule: any = {};
  rulesInProgress = false;
  uriFocused = false;
  whitelistFocused = false;

  constructor(
    private toastr: ToastrService,
    private translate: TranslateService,
    private auth: AuthService,
    public hostModeService: HostModeService,
    private siteApi: OrganizationSiteService,
  ) {}

  ngOnInit(): void {
    this.lang = this.auth.getCurrentLanguage();

    this.siteApi.getSite(this.siteName).subscribe({
      next: (site) => {
        this.site = site;
        this.disabled = !this.hostModeService.isAdvanced(this.site.mode);
      },
      error: (err) => this.toastr.error(`Failed to load site: ${err}`),
    });
    this.loadRules();
  }

  private loadRules() {
    this.siteApi.getRules(this.siteName).subscribe(
      (rules) =>
        (this.rules = _(rules)
          .sortBy((rule) => rule.priority)
          .value()),
    );
  }

  onFocusUri(element) {
    if (!element.value) element.value = '/';
  }

  onBlurUri(element) {
    if (element.value == '/') element.value = '';
  }

  addPath(rule: RuleVM, event) {
    let path = event.target.value.trim();

    if (rule.paths.includes(path)
      || (rule.active && this.rules.filter(rule => rule.active).some(rule => rule.paths.includes(path)))) {
      return this.toastr.error(this.translate.instant('AlreadyExists'));
    }

    rule.paths.push(path);

    event.target.value = '/';
  }

  addWhitelistedIp(rule, event) {
    let ip = subnet(event.target.value.trim());

    if (rule.whitelistedIps.includes(ip)) {
      return this.toastr.error(this.translate.instant('AlreadyExists'));
    }

    rule.whitelistedIps.push(ip);

    event.target.value = '';
  }

  deleteFromRule(rule, category, value) {
    rule[category].splice(
      rule[category].findIndex((item) => item == value),
      1,
    );
  }

  startEditing(rule: RuleVM) {
    this.tmpRule = copyObject(rule); // deep copy in order to restore if cancelling editing
    this.editingRule = rule;
  }

  validateEditing(rule: RuleVM) {
    if (rule.paths.length && rule.whitelistedIps.length) {
      const { id, createdAt, updatedAt, uris, ...data } = rule;

      if (!id) {
        this.rulesInProgress = true;
        this.siteApi.createRule(this.siteName, data).subscribe({
          next: (res) => {
            this.loadRules();
            this.rulesInProgress = false;
            this.editingRule = null;
          },
          error: (error: any) => {
            this.toastr.error('Failed to save rule');
            console.error('Error : ', error);
            this.rulesInProgress = false;
          },
        });
      } else {
        this.updateRule({ id, ...data });
      }
    }
  }

  updateRule(rule: Rule) {
    this.rulesInProgress = true;
    this.siteApi.updateRule(this.siteName, rule).subscribe({
      next: (res) => {
        this.loadRules();
        this.toastr.success();
        this.rulesInProgress = false;
        this.editingRule = null;
      },
      error: (error) => {
        this.rulesInProgress = false;
        console.error('Error : ', error);
      },
    });
  }

  cancelEditing(rule: RuleVM) {
    if (!rule.id) this.rules = _.pull(this.rules, this.editingRule);
    else this.rules[this.rules.findIndex((r) => r === this.editingRule)] = this.tmpRule;
    this.editingRule = null;
  }

  addRule() {
    if (this.disabled) {
      return this.hostModeService.promptSuscribeToMode('SuscribeToAdvanced');
    }

    this.editingRule = {
      priority: this.rules.length
        ? Math.max.apply(
            Math,
            this.rules.map((o) => o.priority),
          ) + 1
        : 1,
      active: true,
      paths: [],
      whitelistedIps: [],
      action: 'brain',
      cache: undefined,
      comment: '',
    };
    this.rules.push(this.editingRule);
    setTimeout(() => this.accessRuleUriElement.nativeElement.focus(), 1);
  }

  deleteRule(rule) {
    return Swal.fire({
      html: this.translate.instant(
        rule.id ? 'VoulezVousAbandonnerCetteNouvelleRegle' : 'VoulezVousSupprimerCetteRegle',
      ),
      showCancelButton: true,
      confirmButtonColor: '#4099ff',
      cancelButtonColor: '#d33',
      cancelButtonText: this.translate.instant('Annuler'),
      confirmButtonText: this.translate.instant('YesConfirm'),
      title: this.translate.instant('Confirmation'),
    } as SweetAlertOptions).then((result) => {
      if (result.value) {
        if (rule.id) {
          this.rulesInProgress = true;
          this.siteApi.deleteRule(this.siteName, rule.id).subscribe({
            next: (res) => {
              this.loadRules();
              this.rulesInProgress = false;
              this.toastr.success();
            },
            error: (error: any) => {
              console.error('Error : ', error);
              this.rulesInProgress = false;
              this.toastr.error('Failed to delete rule');
            },
          });
        } else {
          this.rules.splice(
            this.rules.findIndex((r) => r === this.editingRule),
            1,
          );
        }
      }
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.rules, event.previousIndex, event.currentIndex);
    if (event.previousIndex != event.currentIndex) {
      this.rules.map((rule, index) => (rule.priority = index + 1));
      this.updateRule(this.rules[event.currentIndex] as Rule);
    }
  }

  validateUri(uri) {
    return validatePath(uri);
  }

  validateIp(ip) {
    return validateIp(ip);
  }
}

type RuleVM = MakeOptional<Rule, 'id'> & Partial<Audit>
  & {uris?: string[]}; // TODO: remove when no longer returned by backend
