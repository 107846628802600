import { Injectable } from '@angular/core';
import { ActiveToast, IndividualConfig, ToastrService as NgxToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { OgoResponse, OgoResponseItems } from '../../services/ogo-response';

@Injectable({
  providedIn: 'root',
})
export class ToastrService {
  constructor(
    private toastr: NgxToastrService,
    private translate: TranslateService,
  ) {}

  error<ConfigPayload = any>(
    message?: string,
    title?: string,
    override?: Partial<IndividualConfig<ConfigPayload>>,
  ): ActiveToast<any> {
    if (!message && !title) title = this.translate.instant('OperationFailed');
    return this.toastr.error(message, title, { timeOut: 8000, extendedTimeOut: 8000, ...override });
  }

  success<ConfigPayload = any>(
    message?: string,
    title?: string,
    override?: Partial<IndividualConfig<ConfigPayload>>,
  ): ActiveToast<any> {
    if (!message && !title) title = this.translate.instant('OperationSuccess');
    return this.toastr.success(message, title, override);
  }

  info<ConfigPayload = any>(
    message?: string,
    title?: string,
    override?: Partial<IndividualConfig<ConfigPayload>>,
  ): ActiveToast<any> {
    return this.toastr.info(message, title, override);
  }

  warning<ConfigPayload = any>(
    message?: string,
    title?: string,
    override?: Partial<IndividualConfig<ConfigPayload>>,
  ): ActiveToast<any> {
    return this.toastr.warning(message, title, override);
  }

  clear(toastId?: number): void {
    this.toastr.clear(toastId);
  }
}
