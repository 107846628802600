import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { SpringPage } from '../shared/page';
import { Lang } from '../shared/utils/lang';
import { Fonctionnalite } from './entities/user';
import { MFA } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  roles = {
    viewer: 'VIEWER',
    siteAdmin: 'SITE_ADMIN',
    clusterAdmin: 'CLUSTER_ADMIN',
    accountAdmin: 'ORGANIZATION_ADMIN',
    owner: 'OWNER',
    admin: 'admin',
  };

  public rolesMap = [
    { libelle: 'Viewer', value: this.roles.viewer },
    { libelle: 'SiteAdmin', value: this.roles.siteAdmin },
    { libelle: 'ClusterAdmin', value: this.roles.clusterAdmin },
    { libelle: 'AccountAdmin', value: this.roles.accountAdmin },
    { libelle: 'Owner', value: this.roles.owner },
  ];

  constructor(private http: HttpClient) {}

  async loadUsersPaginated(searchTerm, index, size) {
    return firstValueFrom(
      this.http.get<SpringPage<User>>(`admin/users?page=${index}&size=${size}&filter=${searchTerm}`, {}),
    );
  }
}

export type User = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  hasApiKey: boolean;
  profile: string;
  lang: Lang;
  lastLoginDate: number | string;
  organizations: {
    role: string;
    organization: {
      id: number;
      code: string;
      companyName: string;
    };
  }[];
  createdAt: number | string;
  createdBy: {
    firstName: string;
    lastName: string;
  };
};
