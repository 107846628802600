import { asLangOrDefault, Lang } from '../../shared/utils/lang';

export default class User {
  email: string;
  id: number;
  lastName: string;
  firstName: string;
  lang: Lang;
  mfa: string;
  profilCode: any;
  datasFonctionnalite: Fonctionnalite[];
  hasApiKey: boolean;

  constructor({ email, id, firstName, lastName, lang, mfa, profilCode, datasFonctionnalite, hasApiKey }) {
    this.email = email;
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.lang = asLangOrDefault(lang);
    this.mfa = mfa;
    this.profilCode = profilCode;
    this.datasFonctionnalite = datasFonctionnalite;
    this.hasApiKey = hasApiKey;
  }

  addFeature(code) {
    this.datasFonctionnalite.push({ code });
  }

  hasFeature(code) {
    return !!this.datasFonctionnalite.find((f) => f.code == code);
  }
}

export interface Fonctionnalite {
  code: string;
}
