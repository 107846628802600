import { Component, Input } from '@angular/core';
import { Organization } from '../../../services/entities/organization';
import { PageRequest, SpringPage } from '../../../shared/page';
import { Contract } from '../../../services/entities/contract';
import { AdminOrganizationService, OrganizationUser } from '../../../services/admin-organization.service';
import { AdminService } from '../../../services/admin.service';

@Component({
  selector: 'app-admin-organization-users',
  standalone: false,

  templateUrl: './admin-organization-users.component.html',
  styleUrls: ['./admin-organization-users.component.scss', '../../../../assets/icon/icofont/css/icofont.scss'],
})
export class AdminOrganizationUsersComponent {
  @Input({ required: true }) organizationCode: string;
  @Input({ required: true }) organizationId: number;

  data: SpringPage<OrganizationUser> | undefined;
  page: PageRequest = { number: 0, size: 10 };

  constructor(
    private adminService: AdminService,
    private organizationService: AdminOrganizationService,
  ) {}

  ngOnInit() {
    this.refreshData();
  }

  changePage(evt) {
    this.page = { ...this.page, number: evt.page - 1 };
    this.refreshData();
  }

  async refreshData() {
    this.data = await this.organizationService.getOrganizationUsers(
      this.organizationCode,
      this.page.number,
      this.page.size,
    );
  }

  impersonate(userId: number) {
    this.adminService.connectAsUser(userId, this.organizationId);
  }
}
