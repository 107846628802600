import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './services/auth.service';
import { AUTH_LOGIN_ROUTE } from './app-routing.module';
import { enableGrooveHQWidget } from './groovehq';
import { CONFIG, Config } from '../config/config';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    @Inject(CONFIG) private config: Config,
    private auth: AuthService,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkLogin(state.url);
  }

  async checkLogin(url: string) {
    if (this.auth.isAuthenticated()) {
      if (!this.auth.currentOrganization?.id) {
        await this.auth.init();

        if (!window['groove'] && this.config.enableGrooveHQ) {
          enableGrooveHQWidget(
            this.auth.getCurrentLanguage(),
            this.auth.getUser().email,
            this.auth.currentOrganization.code,
          );
        }
      }
      return true;
    }

    this.auth.redirectUrl = url;

    this.router.navigate([AUTH_LOGIN_ROUTE]);
    return false;
  }
}
