<div class="container-fluid">
  <div class="card p-3">
    <div class="row">
      <div class="col-12">
        <h3>
          OGO API Doc
          <button
            class="btn btn-primary"
            title="{{ 'Download' | translate }}"
            (click)="downloadApiDoc()"
            style="float: right; position: relative; top: -6px"
          >
            {{ 'Telecharger' | translate }} API DOC Postman&nbsp;&nbsp;<i class="icofont icofont-download-alt"></i>
          </button>
        </h3>
        <hr />
        <pre
          class="bg-grey"
          style="font-size: 14px; line-height: 2; padding: 20px !important"
        >API_HOST = {{ apiUrl }}<br/>USERNAME = {{ userEmail }}<br/>token = md5(concat(ENDPOINT, '-', API_KEY))&nbsp;<label class="badge badge-sm bg-dark mb-0" placement="bottom" [ngbTooltip]="computeApiTokenTooltip">?</label></pre>
        <ng-template #computeApiTokenTooltip>
          <div innerHTML="{{ 'ComputeAPIToken' | translate }}"></div>
        </ng-template>
        <p [innerHTML]="'api-organizationCodeHeader-1' | translate: { header }"></p>
        <hr />
        @if (collection) {
          <div *ngFor="let item of collection.item; let i = index">
            <div class="flex-align-items space-between pointer" (click)="item.open = !item.open">
              <div>
                <h4 style="color: #4099ff">{{ item.name }}</h4>
                <p>{{ item.description }}</p>
              </div>
              <i *ngIf="!item.open" class="icofont icofont-simple-down collapse-arrow"></i>
              <i *ngIf="item.open" class="icofont icofont-simple-up collapse-arrow"></i>
            </div>
            <ng-container *ngIf="item.open">
              <div *ngFor="let subitem of item.item" style="margin-bottom: 35px">
                <div class="flex-align-items space-between pointer" (click)="subitem.open = !subitem.open">
                  <h5 style="margin-bottom: 10px">
                    <span class="method-{{ subitem.request.method }}">{{ subitem.request.method }}</span>
                    {{ subitem.name }}
                    <i
                      *ngIf="!subitem.open"
                      class="icofont icofont-simple-down collapse-arrow"
                      style="position: relative; top: 3px"
                    ></i>
                    <i
                      *ngIf="subitem.open"
                      class="icofont icofont-simple-up collapse-arrow"
                      style="position: relative; top: 3px"
                    ></i>
                  </h5>
                </div>
                <div *ngIf="subitem.open" style="margin-bottom: 50px">
                  <pre>{{ subitem.request.description }}</pre>
                  <h6>{{ 'Request' | translate }}</h6>
                  <pre class="bg-grey" style="margin-bottom: 10px; width: 50%">{{ subitem.request.url.raw }}</pre>
                  <div *ngIf="subitem.request && subitem.request.header">
                    <h6>{{ 'Headers' | translate }}</h6>
                    <p *ngFor="let header of subitem.request.header">{{ header.key }} : {{ header.value }}</p>
                  </div>
                  <div *ngIf="subitem.request && subitem.request.url && subitem.request.url.query">
                    <h6>{{ 'Params' | translate }}</h6>
                    <p *ngFor="let header of subitem.request.url.query">{{ header.key }} : {{ header.value }}</p>
                  </div>
                  <div *ngIf="subitem.request && subitem.request.body && subitem.request.body.raw">
                    <h6>{{ 'Body' | translate }}</h6>
                    <pre class="bg-grey">{{ subitem.request.body.raw }}</pre>
                  </div>
                  <div *ngIf="subitem.response && subitem.response[0] && subitem.response[0].body">
                    <h6>{{ 'ResponseExample' | translate }}</h6>
                    <pre class="bg-grey">{{ subitem.response[0].body }}</pre>
                  </div>
                </div>
              </div>
            </ng-container>
            <hr *ngIf="i + 1 != collection.item.length" />
          </div>
        }
      </div>
    </div>
  </div>
</div>
