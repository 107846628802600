<div class="container-fluid">
  <app-cluster-selector
    [cluster]="cluster"
    [clusters]="clusters"
    (selected)="searchByCluster($event)"
  ></app-cluster-selector>

  <div class="card p-3">
    <h4 class="mb-0">{{ "Journal d'action" | translate }}s</h4>
    <hr />
    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <label>
            <b>{{ 'Search' | translate }}</b>
          </label>
          <input
            #search
            class="form-control form-control-sm"
            type="text"
            placeholder="{{ 'PlaceholderJournalSearch' | translate }}"
            (input)="resetIndex()"
          />
        </div>
      </div>
      <div class="col-md-5">
        <div class="form-group">
          <label
            ><b>{{ 'Période' | translate }}</b></label
          >
          <div class="flex-align-items">
            <button
              app-date-range-selector
              [range]="periode"
              [ranges]="['15M', '1H', '4H', 'T', 'Y', '7d', '30d', '90d']"
              [timePicker]="true"
              (rangeChange)="onPeriodEmitted($event)"
              style="font-size: 14px; padding: 6px 14px"
              class="btn btn-sm btn-primary flex-align-items"
              id="date-range-selector-journal"
            >
              <i class="icofont icofont-ui-calendar mr-2"></i>
              <span></span>
              <div class="arrow-down"></div>
            </button>
            <button
              (click)="getLogs(this.cluster.name)"
              class="btn btn-primary btn-sm ml-3"
              title="{{ 'Refresh' | translate }}"
              style="height: 30px"
            >
              <i class="icon-reload"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="journal-table table table-striped table-bordered table-xs">
        <thead>
          <tr>
            <th class="expand-button"></th>
            <th class="date">Date</th>
            <th class="cluster">Cluster</th>
            <th class="author">{{ 'Auteur' | translate }}</th>
            <th class="entity">{{ 'Entité' | translate }}</th>
            <th class="action">Action</th>
            <th class="site">{{ 'Hôte' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <ng-template
            ngFor
            let-item
            [ngForOf]="
              logs
                | paginate
                  : {
                      itemsPerPage: pageSize,
                      currentPage: page,
                      totalItems: totalItems,
                    }
            "
          >
            <tr>
              <td>
                <button class="btn-dark" *ngIf="item.plus != true" (click)="item.plus = true">+</button>
                <button class="btn-dark" *ngIf="item.plus == true" (click)="item.plus = false">-</button>
              </td>
              <td class="date">
                {{ item.dateAction | dateFormat }}
              </td>
              <td>{{ item.clusterName }}</td>
              <td>{{ item.actionAuthorEmail ? item.actionAuthorEmail : item.actionAuthorDetail?.email }}</td>
              <td>{{ item.recordTable }}</td>
              <td>{{ item.action }}</td>
              <td>{{ item.siteTrackedName }}</td>
            </tr>
            <tr *ngIf="item.plus == true">
              <td colspan="8" style="padding: 10px 20px">
                <div class="flex-align-items">
                  <div style="flex-basis: 45%">
                    <json-viewer [json]="item.recordBefore" [expanded]="true"></json-viewer>
                  </div>
                  <i style="flex-basis: 10%; font-size: 35px; color: #333" class="fa fa-long-arrow-right"></i>
                  <div style="flex-basis: 45%">
                    <json-viewer [json]="item.record" [expanded]="true"></json-viewer>
                  </div>
                </div>
              </td>
            </tr>
          </ng-template>
          <tr *ngIf="logs.length === 0 && !isLoading">
            <td class="text-center h6" colspan="8">
              {{ 'NoData' | translate }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-md-12">
      <pagination-controls
        class="pull-right"
        (pageChange)="changePage($event)"
        previousLabel="{{ 'Previous' | translate }}"
        nextLabel="{{ 'Next' | translate }}"
      ></pagination-controls>
    </div>
  </div>
</div>
