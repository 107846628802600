<app-cluster-selector
  [cluster]="cluster"
  [clusters]="clusters"
  (selected)="clusterSelected($event)"
></app-cluster-selector>

<div class="card p-3">
  <h4 class="flex-align-items space-between mb-0">
    <span>{{ 'Délégation du cluster' | translate }}</span>
    <button (click)="modalAddDelegation.show()" class="btn btn-sm btn-primary">
      <i class="icofont icofont-ui-add"></i>
      {{ 'AddDelegation' | translate }}
    </button>
  </h4>
  <hr />
  <div class="row">
    <div class="col-md-2">
      <div class="form-group">
        <label>
          <b>{{ 'Search' | translate }}</b>
        </label>
        <input
          #search
          id="search"
          class="form-control form-control-sm"
          type="text"
          [placeholder]="'Organization' | translate"
        />
      </div>
    </div>
    <div class="col-md-5">
      <div class="form-group">
        <label> <b>Type</b> </label>
        <select
          [(ngModel)]="filter.type"
          (change)="getDelegation()"
          class="form-control form-control-sm"
          style="max-width: 200px"
        >
          <option value="">{{ 'All' | translate }}</option>
          <ng-container *ngFor="let role of roles">
            <option [value]="role">{{ 'ClusterDelegation.' + role | translate }}</option>
          </ng-container>
        </select>
      </div>
    </div>
    <div class="col-md-12 h6">
      {{ 'ClusterOwner' | translate }}&nbsp;:
      <span class="text-primary">{{ cluster?.owner?.companyName }}</span>
    </div>
  </div>
  <div class="table-responsive">
    <table class="delegation-table table table-striped table-bordered table-xs">
      <thead>
        <tr>
          <th>{{ 'Organization' | translate }}</th>
          <th>Code</th>
          <th>
            <span>Type</span>
            <label class="badge badge-sm bg-dark mb-0 ml-1" [ngbTooltip]="delegationTypeTooltip">?</label>
            <ng-template #delegationTypeTooltip>
              <div innerHTML="{{ 'DelegationTypeTooltip' | translate }}"></div>
            </ng-template>
          </th>
          <th>{{ 'Creation' | translate }}</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let item of clusterSharedWith | paginate: { itemsPerPage: size, currentPage: index + 1, totalItems: total }
          "
        >
          <td>{{ item.recipient.companyName }}</td>
          <td><app-badge-organization [code]="item.recipient.code"></app-badge-organization></td>
          <td>
            <select
              class="form-control"
              style="font-weight: normal; max-width: 200px"
              (change)="confirmUpdateDelegation(item)"
              name="radio"
              [(ngModel)]="item.role"
              type="checkbox"
            >
              <ng-container *ngFor="let role of roles">
                <option [value]="role">{{ 'ClusterDelegation.' + role | translate }}</option>
              </ng-container>
            </select>
          </td>
          <td>{{ item.createdAt | dateFormat }}</td>
          <td>
            <span>
              <i
                (click)="confirmDeleteDelegation(item)"
                style="color: #e74c3c; font-size: 20px"
                class="icofont icofont-ui-delete"
              ></i>
            </span>
          </td>
        </tr>
        <tr *ngIf="clusterSharedWith.length === 0 && !isLoading">
          <td class="text-center h4" colspan="6">{{ 'Aucune donnée à afficher' | translate }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-md-12">
    <pagination-controls
      class="pull-right"
      (pageChange)="changePage($event)"
      previousLabel="{{ 'Previous' | translate }}"
      nextLabel="{{ 'Next' | translate }}"
    ></pagination-controls>
  </div>
</div>

<app-modal-basic #modalAddDelegation [dialogClass]="'modal-md'">
  <div class="app-modal-header">
    <h4 class="modal-title">
      <small>
        {{ 'AddDelegation' | translate }} /
        <span class="text-primary">{{ cluster.name }}</span>
      </small>
    </h4>
    <button type="button" class="close basic-close" (click)="modalAddDelegation.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label>{{ 'EmailOrOrganizationCode' | translate }}</label>
          <input [(ngModel)]="share.recipient" type="text" class="form-control form-control-sm" />
        </div>
        <div class="form-group">
          <label>
            {{ 'Type' | translate }}
            <label class="badge badge-sm bg-dark mb-0 ml-1" [ngbTooltip]="delegationTypeTooltip">?</label>
          </label>
          <select [(ngModel)]="share.role" class="form-control form-control-sm">
            <ng-container *ngFor="let role of roles">
              <option [value]="role">{{ 'ClusterDelegation.' + role | translate }}</option>
            </ng-container>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="app-modal-footer pull-right">
    <button
      (click)="createDelegation(share)"
      class="btn btn-primary btn-sm"
      [disabled]="!share.recipient || !share.role"
      [innerHTML]="'Ajouter' | translate"
    ></button>
    &nbsp;
    <button
      (click)="modalAddDelegation.hide()"
      class="btn btn-danger btn-sm"
      [innerHTML]="'Fermer' | translate"
    ></button>
  </div>
</app-modal-basic>
