import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ToastrService } from 'app/shared/toastr/toastr.service';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AdminService } from 'app/services/admin.service';
import { PageView } from 'app/enums/enums';
import { AuthService } from '../../../services/auth.service';
import { copyObject } from 'app/shared/utils/data-utils';
import { Organization } from 'app/services/entities/organization';
import { AdminOrganizationService, OrganizationListItem } from '../../../services/admin-organization.service';
import { ADD_ROUTE } from 'app/app-routing.module';

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss', '../../../../assets/icon/icofont/css/icofont.scss'],
  standalone: false,
})
export class OrganizationsComponent implements OnInit {
  @ViewChild('remove') remove: any;
  @ViewChild('search') search: ElementRef;
  @ViewChild('ownsSite') ownsSite: ElementRef;

  organization: Organization;
  organizations: OrganizationListItem[] = [];
  clusters: any = [];

  page: number = 1;
  pageSize: number = 20;
  totalItems: number = 0;

  constructor(
    private translate: TranslateService,
    private toastr: ToastrService,
    public auth: AuthService,
    private admin: AdminService,
    private adminOrganizationService: AdminOrganizationService,
  ) {}

  async ngOnInit() {
    await this.refreshList();
  }

  ngAfterViewInit(): void {
    fromEvent(this.search.nativeElement, 'keyup')
      .pipe(debounceTime(300))
      .subscribe(async (evt: any) => {
        this.page = 0;
        console.log(`filter: ${evt.target.value}`);
        await this.refreshList();
      });
  }

  connectAsOwner(organization: OrganizationListItem) {
    this.admin.connectAsUser(organization.owner.id, organization.id);
  }

  modalRemove(p: any) {
    this.remove.show();
    this.organization = { ...p };
  }

  async changePage(evt: any) {
    this.page = evt;
    await this.refreshList();
  }

  async refreshList() {
    const searchTerm = this.search?.nativeElement?.value;
    let res: any = null;
    try {
      res = await this.adminOrganizationService.getOrganizations(
        { searchTerm, ownsSite: this?.ownsSite?.nativeElement?.checked },
        this.page - 1,
      );
    } catch (error) {
      return this.toastr.error(error.error);
    }

    this.organizations = res?.content;
    this.totalItems = res?.totalElements;
  }

  async removeOrganization(id) {
    try {
      await this.adminOrganizationService.deleteOrganization(id);
    } catch (error) {
      return this.toastr.error(this.translate.instant(error?.error?.error || 'Erreur'));
    }

    this.toastr.success(this.translate.instant('SuppressionSucces'));
    this.remove.hide();
    await this.refreshList();
  }

  protected readonly ADD_ROUTE = ADD_ROUTE;
}
