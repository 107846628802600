import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import _ from 'lodash';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AuthService } from 'app/services/auth.service';
import { formatHumanReadableDate } from 'app/shared/utils/data-utils';
import { RANGE_PRESETS } from 'app/shared/date-range-selector/calendar-data.service';
import { DateRange } from '../../shared/utils/date-range';
import { Cluster } from '../../services/sites.service';

@Component({
  selector: 'app-journal-admin-cluster',
  templateUrl: './journal-admin-cluster.component.html',
  styleUrls: ['./journal-admin-cluster.component.scss', '../../../assets/icon/icofont/css/icofont.scss'],
  standalone: false,
})
export class JournalAdminClusterComponent implements OnInit, AfterViewInit {
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private auth: AuthService,
  ) {}
  lang: string;

  adminView = false;
  isLoading: boolean = false;
  page = 1;
  pageSize = 10;
  totalItems: number;
  periode: DateRange;
  sub: any;
  logs: any = [];
  tmpLogs: any = [];
  cluster: Cluster;
  clusters: Cluster[] = [];
  @ViewChild('site') site: ElementRef;
  @ViewChild('search') search: ElementRef;

  ngOnInit() {
    this.lang = this.auth.getCurrentLanguage();
    this.adminView = this.auth.userIsAdmin();

    this.periode = RANGE_PRESETS['90d'];

    this.route.data.subscribe((data: { clusters: any }) => {
      this.clusters = data.clusters;
      this.cluster = this.clusters[0];
      if (this.auth.userIsAdmin()) this.clusters.push({ name: 'admin', clusterId: 'admin', id: 0 });
      this.getLogs(this.cluster.name);
    });
  }

  ngAfterViewInit(): void {
    fromEvent(this.search.nativeElement, 'keyup')
      .pipe(debounceTime(200))
      .subscribe((evt: any) => this.getLogs(this.cluster.name));
  }

  onPeriodEmitted(p: DateRange) {
    this.periode = p;
    this.getLogs(this.cluster.name);
  }

  searchByCluster(cluster) {
    this.cluster = this.clusters.find((c) => c.name == cluster.name);
    this.getLogs(this.cluster.name);
  }

  resetIndex() {
    this.page = 1;
  }

  changePage(evt: any) {
    this.page = evt;
    this.getLogs(this.cluster.name);
  }

  getLogs(clusterName: string) {
    if (!clusterName || !this.periode) {
      return;
    }
    this.isLoading = true;
    this.periode = this.periode.refresh();
    let data = {
      clusterName,
      startDate: this.periode.start.toISOString(),
      endDate: this.periode.end.toISOString(),
    };

    if (this.search) {
      data['actionAuthorDetail'] = this.search.nativeElement.value;
      data['action'] = this.search.nativeElement.value;
      data['siteTrackedName'] = this.search.nativeElement.value;
      data['recordTable'] = this.search.nativeElement.value;
      data['actionAuthorDetailParam'] = { operator: '%%' };
      data['actionParam'] = { operator: '%%' };
      data['siteTrackedNameParam'] = { operator: '%%' };
      data['recordTableParam'] = { operator: '%%' };
    }

    this.http
      .post('siteTracking/get', {
        index: this.page - 1,
        size: this.pageSize,
        data: data,
      })
      .subscribe((res: any) => {
        if (!res.hasError) {
          this.totalItems = res.count;
          this.logs = _.map(res.items, (p: any) => {
            p.itemChanged = p.record && p.recordBefore;
            p.record = this.parseJson(p.record);
            p.recordBefore = this.parseJson(p.recordBefore);
            p.actionAuthorDetail = this.parseJson(p.actionAuthorDetail);
            if (p.recordTable == 'certificat' || p.recordTable == 'Certificat') {
              delete p?.recordBefore?.privateKey;
              delete p?.recordBefore?.publicKey;
              delete p?.record?.privateKey;
              delete p?.record?.publicKey;
            }
            this.formatDates(p?.recordBefore);
            this.formatDates(p?.record);
            return p;
          });
          this.tmpLogs = this.logs;
        }
        this.isLoading = false;
      });
  }

  parseJson(jsonData: any) {
    try {
      if (!jsonData) return {};
      else if (typeof jsonData === 'string') return JSON.parse(jsonData);
      else if (typeof jsonData === 'object') return jsonData;
      else throw new Error(`Expecting string or object. Got ${typeof jsonData}`);
    } catch (e) {
      console.error(e);
      return { invalidJson: jsonData };
    }
  }

  formatDates(record) {
    if (record?.createdAt) record.createdAt = formatHumanReadableDate(record.createdAt, this.lang, 'long');
    if (record?.updatedAt) record.updatedAt = formatHumanReadableDate(record.updatedAt, this.lang, 'long');
  }
}
