<div class="row mb-2">
  <h5 class="col-auto">{{ 'utilisateurs' | translate }}</h5>
</div>

<ngx-datatable
  #myTable
  class="data-table table-responsive task-list-table"
  [rows]="data?.content"
  [columnMode]="'force'"
  [headerHeight]="50"
  [footerHeight]="50"
  [limit]="10"
  [rowHeight]="40"
>
  <ngx-datatable-column [flexGrow]="2" name="name">
    <ng-template let-column="column" ngx-datatable-header-template>
      <p class="dataheader" [innerHtml]="'NomPrénom(s)' | translate"></p>
    </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.user.firstName }} {{ row.user.lastName }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="3" name="email">
    <ng-template let-column="column" ngx-datatable-header-template>
      <p class="dataheader" [innerHtml]="'Email' | translate"></p>
    </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.user.email }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="3" name="role">
    <ng-template let-column="column" ngx-datatable-header-template>
      <p class="dataheader" [innerHtml]="'role' | translate"></p>
    </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ 'UserRole.' + row.role | translate }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="3" name="actions" [maxWidth]="40" cellClass="actions">
    <ng-template let-column="column" ngx-datatable-header-template></ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
      <i
        (click)="impersonate(row.user.id)"
        style="color: #4099ff; font-size: 20px"
        class="pointer icofont icofont-eye"
        title="{{ 'ConnectAsThisUser' | translate }}"
      ></i
      >&nbsp;&nbsp;
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-footer>
    <ng-template ngx-datatable-footer-template>
      <app-table-pager [data]="data" [page]="page" [changePage]="changePage"></app-table-pager>
    </ng-template>
  </ngx-datatable-footer>
</ngx-datatable>
