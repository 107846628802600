import Swal, { SweetAlertOptions } from 'sweetalert2';
import { concatMap, from, Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { map } from 'rxjs/operators';

export function confirm(
  alertOptions: SweetAlertOptions,
  onConfirm: () => any,
  onCancel?: () => any,
): Observable<boolean> {
  return fromPromise(Swal.fire(alertOptions)).pipe(
    map((confirm) => {
      if (confirm.value) {
        onConfirm();
        return true;
      } else {
        if (onCancel) onCancel();
        return false;
      }
    }),
  );
}

export function confirmPipe(
  alertOptions: SweetAlertOptions,
  onConfirm: () => Observable<boolean>,
  onCancel?: () => any,
): Observable<boolean> {
  return fromPromise(Swal.fire(alertOptions)).pipe(
    concatMap((confirm) => {
      if (confirm.value) {
        return onConfirm();
      } else {
        if (onCancel) onCancel();
        return from([false]);
      }
    }),
  );
}
