<div class="app-modal-body">
  <div class="card container-fluid px-lg-4 with-tabs p-4">
    <div class="row mx-lg-n4">
      <div class="col-xxl-6 px-lg-4">
        <div class="form-group">
          <app-label label="NDD" tooltip="VeuiEntrAdrSit" />
          <div class="form-group form-row">
            <div class="col">
              <input
                id="textInput"
                (paste)="onPasteDomain($event)"
                placeholder="{{ 'RentrezURLSite' | translate }}"
                [disabled]="mode == EditMode.UPDATE"
                [(ngModel)]="site.name"
                name="site"
                class="form-control"
                type="text"
                #domainName
              />
            </div>
            <div class="col-auto">
              <button
                (click)="checkDns(site.name)"
                class="btn btn-sm btn-outline-primary"
                [innerHTML]="'CheckDNS' | translate"
                [disabled]="!site.name?.length"
              ></button>
            </div>
          </div>
        </div>
        <div class="form-group details resolve">
          <p>
            <b>{{ 'ResolveInfos' | translate }}:</b>
          </p>
          <span
            *ngIf="!urlParam.ipv4Address && !urlParam.ipv6Address && !urlParam.cname && !urlParam.noRecord"
            class="text-placeholder resolve"
            style="margin-left: 0px"
          >
            <span class="activity"></span>
          </span>
          <p *ngIf="urlParam.noRecord">
            {{ 'NoRecord' | translate }}
          </p>
          <p *ngIf="urlParam.cname">
            <strong>CNAME :</strong>
            <span>&nbsp;{{ urlParam.cname }}</span>
          </p>
          <p *ngIf="urlParam.ipv4Address">
            <strong>IPv4 :</strong>
            <span>&nbsp;{{ urlParam.ipv4Address }}</span>
          </p>
          <p *ngIf="urlParam.ipv6Address">
            <strong>IPv6 :</strong>
            <span>&nbsp;{{ urlParam.ipv6Address }}</span>
          </p>
          <p
            *ngIf="urlParam.ipv4Address && urlParam.ipv6Address && cluster && !cluster.supportsIpv6Origins"
            class="warning"
          >
            <i class="icofont icofont-warning resolve-icon warning"></i>
            {{ 'NotIPv6Cluster' | translate }}
          </p>
          <p *ngIf="!urlParam.ipv4Address && urlParam.ipv6Address" class="error">
            <i class="icofont icofont-error resolve-icon error" style="top: 3px"></i>
            {{ 'OnlyIPv6OnNotIPv6Cluster' | translate }}
          </p>
        </div>
      </div>
      <div class="col-xxl-6 px-lg-4">
        <div class="form-group">
          <app-label label="Cluster" tooltip="VeuilSelClust" />
          <select
            *ngIf="mode == EditMode.CREATE"
            [(ngModel)]="cluster"
            (change)="clusterChange()"
            name="cluster"
            class="form-control"
          >
            <option *ngFor="let cluster of clusters" [ngValue]="cluster.cluster">
              {{ cluster.cluster.name }}
            </option>
          </select>
          <input
            *ngIf="mode == EditMode.UPDATE && cluster"
            [(ngModel)]="cluster.name"
            name="cluster"
            class="form-control"
            type="text"
            disabled
          />
        </div>
        <div class="form-group">
          <div class="flex-align-items" style="gap: 1ex">
            <div class="form-group" style="width: 100%">
              <app-label label="OriginServerIP" tooltip="DestinIsAddSite" />
              <input
                id="proxy"
                placeholder="{{ 'RentrezIPDest' | translate }}"
                [(ngModel)]="site.destHost"
                (paste)="onPasteDestination($event)"
                name="destination"
                class="form-control"
                type="text"
              />
            </div>
            <div class="form-group port">
              <app-label label="Port" tooltip="PortinIsAddSite" tooltipPlacement="left" />
              <div class="flex-align-items">
                <div class="input-prefix">
                  <div style="position: relative; top: 5px">:</div>
                </div>
                <input
                  id="port"
                  placeholder="{{ site.destHostScheme == 'http' ? 80 : 443 }}"
                  [(ngModel)]="site.port"
                  name="port"
                  class="form-control"
                  type="number"
                  [ngStyle]="{ border: site.port ? '1px solid #7bc5ff' : '' }"
                />
                <button
                  (click)="diagnose()"
                  [disabled]="!(site.name && site.destHost && cluster)"
                  class="btn btn-sm btn-outline-primary ml-2"
                  style="white-space: nowrap"
                  [innerHTML]="'DiagnoseOrigin' | translate"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 px-lg-4">
        <div *ngIf="diagnoseResult" class="bg-blue">
          <div *ngIf="diagnoseResult.IssueDetected" class="flex-align-items mt-1" style="color: #d28c34">
            <i class="icofont icofont-warning icon-diag mr-2"></i>
            <label> {{ 'SiteMetIssues' | translate }} : </label>
          </div>
          <div *ngIf="!diagnoseResult.IssueDetected" class="flex-align-items mt-2" style="color: #0fb10f">
            <i class="icofont icofont-check icon-diag mr-2"></i>
            <label> {{ 'DiagnosticOk' | translate }} : </label>
          </div>
          <div *ngFor="let backend of diagnoseResult.Backends | keyvalue" style="text-align: left">
            <div>
              {{ 'DNSResolve' | translate }} :
              <i
                *ngIf="backend.value.ResolvStatus == 'OK'"
                class="icofont icofont-verification-check"
                style="color: #0fb10f"
              ></i
              ><i *ngIf="backend.value.ResolvStatus == 'KO'" class="icofont icofont-warning" style="color: #d28c34"></i>
            </div>
            <div class="mt-1" *ngFor="let ip of backend.value.IPs | keyvalue">
              <b>{{ ip.key }}</b>
              <div class="mt-1 ml-2">
                <div>
                  HTTP :
                  <i
                    *ngIf="ip.value.HttpDiag.Status == 'OK'"
                    class="icofont icofont-verification-check"
                    style="color: #0fb10f"
                  ></i
                  ><i
                    *ngIf="ip.value.HttpDiag.Status == 'KO'"
                    class="icofont icofont-warning"
                    style="color: #d28c34"
                  ></i>
                  <div *ngIf="ip.value.HttpDiag.Issues && ip.value.HttpDiag.Issues.length" class="mb-2">
                    <p>{{ 'DetectedIssues' | translate }} :</p>
                    <div class="ml-2" *ngFor="let issue of ip.value.HttpDiag.Issues">
                      <i>{{ issue }}</i>
                    </div>
                  </div>
                </div>
                <div *ngIf="diagnoseHttps">
                  HTTPS :
                  <i
                    *ngIf="ip.value.HttpsDiag.Status == 'OK'"
                    class="icofont icofont-verification-check"
                    style="color: #0fb10f"
                  ></i
                  ><i
                    *ngIf="ip.value.HttpsDiag.Status == 'KO'"
                    class="icofont icofont-warning"
                    style="color: #d28c34"
                  ></i>
                  <div *ngIf="ip.value.HttpsDiag.Issues && ip.value.HttpsDiag.Issues.length" class="mb-2">
                    <p>{{ 'DetectedIssues' | translate }} :</p>
                    <div class="ml-2" *ngFor="let issue of ip.value.HttpsDiag.Issues">
                      <i>{{ issue }}</i>
                    </div>
                  </div>
                </div>
                <div *ngIf="diagnoseHttps">
                  HTTPS2 :
                  <i
                    *ngIf="ip.value.Http2Diag.Status == 'OK'"
                    class="icofont icofont-verification-check"
                    style="color: #0fb10f"
                  ></i
                  ><i
                    *ngIf="ip.value.Http2Diag.Status == 'KO'"
                    class="icofont icofont-warning"
                    style="color: #d28c34"
                  ></i>
                  <div *ngIf="ip.value.Http2Diag.Issues && ip.value.Http2Diag.Issues.length" class="mb-2">
                    <p>{{ 'DetectedIssues' | translate }} :</p>
                    <div class="ml-2" *ngFor="let issue of ip.value.Http2Diag.Issues">
                      <i>{{ issue }}</i>
                    </div>
                  </div>
                </div>
                <div *ngIf="diagnoseHttps && !site.trustSelfSigned">
                  TLS :
                  <i
                    *ngIf="ip.value.TLSDiag.Status == 'OK'"
                    class="icofont icofont-verification-check"
                    style="color: #0fb10f"
                  ></i
                  ><i
                    *ngIf="ip.value.TLSDiag.Status == 'KO'"
                    class="icofont icofont-warning"
                    style="color: #d28c34"
                  ></i>
                  <div *ngIf="ip.value.TLSDiag.Issues && $any(ip.value).TLSDiag.Issues.length" class="mb-2">
                    <p>{{ 'DetectedIssues' | translate }} :</p>
                    <div class="ml-2" *ngFor="let issue of $any(ip.value).TLSDiag.Issues">
                      <i>{{ issue }}</i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="row mx-lg-n4 separator">
      <div class="col-xxl-6 px-lg-4">
        <div class="form-group row https">
          <app-label class="col" label="ProtocoleEntreUtilisateurEtOGO" tooltip="ForcerEnHttpsDescr" />
          <div class="col-xl-auto">
            <select [(ngModel)]="https" name="https" class="form-control">
              <option value="http">{{ 'Non' | translate }}</option>
              <option selected value="https">{{ 'YesWithoutHSTS' | translate }}</option>
              <option value="hsts">{{ 'YesSTS' | translate }}</option>
              <option value="hstss">{{ 'YesSTSIS' | translate }}</option>
              <option value="hstssp">{{ 'YesSTSISP' | translate }}</option>
            </select>
          </div>
        </div>
        <div class="row form-group">
          <app-label class="col" label="ForcerDestinationEn" tooltip="ForceHttps" />
          <div class="col-md-auto">
            <select
              [(ngModel)]="site.destHostScheme"
              (change)="diagnoseResult = null"
              name="originehttps"
              class="form-control"
            >
              <option value="http">http</option>
              <option value="https">https</option>
            </select>
          </div>
        </div>
        <div class="row form-group">
          <app-label class="col" label="ModeAudit" tooltip="ModeAuditBadge" />
          <app-switch class="col-md-auto" [(ngModel)]="site.dryRun" />
        </div>
        <div class="row form-group">
          <app-site-tags class="col" [tags]="tags" [(selected)]="site.tags" />
        </div>
      </div>
      <div class="col-xxl-6 px-lg-4">
        <div class="row form-group">
          <app-label class="col" label="SupprimerLesHeadersXForwarded" tooltip="SupprimerHeaderXForwardDescr" />
          <app-switch [(ngModel)]="site.noCopyXForwardedFor" class="col-md-auto" />
        </div>
        <div class="row form-group">
          <app-label
            label="Ne jamais vérifier le certificat du serveur origine"
            tooltip="JamaisVerifierCertifDescr"
            class="col"
          />
          <app-switch [(ngModel)]="site.trustSelfSigned" (change)="onTrustSelfSignedChange()" class="col-md-auto" />
        </div>
        <div class="row form-group">
          <app-label label="Mode Panique" tooltip="BypassOgoBrain" class="col" />
          <app-switch [(ngModel)]="site.panicMode" class="col-md-auto" />
        </div>
        <div class="row form-group" *ngIf="canSetMode">
          <fieldset class="col">
            <legend>Admin cluster</legend>
            <div class="row form-group">
              <app-label class="col" label="Mode" tooltip="ModeTooltip" tooltipPlacement="top" />
              <div class="col-md-auto">
                <select class="form-control" #hostMode [(ngModel)]="site.mode">
                  <option *ngFor="let m of hostModeService.modes" [value]="m.value">
                    {{ m.libelle | translate }}
                  </option>
                </select>
              </div>
            </div>
          </fieldset>
        </div>
        <div class="row form-group" *ngIf="canSetPushLog">
          <fieldset class="col">
            <legend>{{ 'OrgaOwnerOrSiteAdmin' | translate }}</legend>
            <div class="row">
              <app-label class="col" label="LogExport" tooltip="LogExportTooltip" />&nbsp;
              <app-switch class="col-md-auto" [(ngModel)]="site.logExport" />
            </div>
          </fieldset>
        </div>
      </div>
    </div>
    <hr />
    <div class="flex-align-items space-between" style="align-items: baseline">
      <div class="setup-guide form-group bg-light mb-0 py-1 px-2" style="margin-right: 15px">
        <h6>{{ 'BasculeDns.title' | translate }}</h6>
        <p class="text-s">
          <span
            [innerHTML]="
              (cluster?.ipv6 ? 'BasculeDns.ipv4AndIpv6' : 'BasculeDns.ipv4')
                | translate: { ipv4: cluster.ip, ipv6: cluster.ipv6 }
            "
          ></span>
        </p>
        <ng-container *ngIf="cluster?.cdnCname && site.cdn && site.cdnStatus != 'ACTIVATION_IN_PROGRESS'">
          {{ 'BasculeDns.cdn' | translate }} <strong>{{ site.cluster.cdnCname }}</strong>
        </ng-container>
        <p>
          <ng-container *ngIf="!cluster?.isPublic">
            {{ 'IPsToWhitelist' | translate }} : <strong>{{ cluster.ipsToWhitelist.join(', ') }}.</strong>
          </ng-container>
          <a *ngIf="cluster?.isPublic" href="/api/ogo-backend/public-ip-list" target="_blank"
            >{{ 'IPsToWhitelist' | translate }}.</a
          >
        </p>
      </div>
      <div class="pull-right">
        <button
          name="validate-site"
          [disabled]="validate()"
          *ngIf="mode === EditMode.CREATE"
          (click)="createSite()"
          class="btn btn-sm btn-primary"
          [ngClass]="{ disabled: validate() === true }"
          [innerHTML]="'valider' | translate"
        ></button
        >&nbsp;
        <button
          name="validate-site"
          [disabled]="validate()"
          *ngIf="mode === EditMode.UPDATE"
          (click)="updateSite()"
          [ngClass]="{ disabled: validate() === true }"
          class="btn btn-sm btn-primary"
          [innerHTML]="'valider' | translate"
        ></button>
      </div>
    </div>
  </div>
</div>
