<div class="form-inline">
  <button
    type="button"
    class="btn btn-sm btn-primary mb-2"
    [routerLink]="[ADD_ROUTE]"
    [innerHtml]="'+&nbsp;' + ('Contract.add' | translate)"
  ></button>
  <div class="input-group mb-2 col col-auto">
    <input type="text" #search class="form-control" placeholder="{{ 'Contract.filter.placeholder' | translate }}" />
    <span class="input-group-prepend">
      <span class="input-group-text">
        <i class="icofont icofont-search"></i>
      </span>
    </span>
  </div>
</div>
<div class="card">
  <div *ngIf="contracts.length > 0" class="table-responsive">
    <table class="table table-striped table-condensed">
      <thead>
        <tr>
          <th [innerHtml]="'Contract.number' | translate"></th>
          <th [innerHtml]="'Contract.name' | translate"></th>
          <th [innerHtml]="'Contract.holder' | translate"></th>
          <th [innerHtml]="'Contract.nbSites' | translate"></th>
          <th [innerHtml]="'Contract.nbClusters' | translate"></th>
          <th [innerHtml]="'Contract.millionRequestsPerMonth' | translate"></th>
          <th [innerHtml]="'Contract.nbOrganizations' | translate"></th>
          <th [innerHtml]="'CreatedAt' | translate"></th>
          <th [innerHtml]="'Contract.startDate' | translate"></th>
          <th class="actions" [innerHtml]="'Actions' | translate"></th>
        </tr>
      </thead>
      <tbody>
        <ng-template
          ngFor
          let-item
          [ngForOf]="contracts | paginate: { itemsPerPage: pageSize, currentPage: page, totalItems: totalItems }"
        >
          <tr>
            <td>
              <b>{{ item.number }}</b>
            </td>
            <td>
              <b>{{ item.name }}</b>
            </td>
            <td>
              {{ item.holder.companyName }}
              <app-badge-organization [code]="item.holder.code"></app-badge-organization>
            </td>
            <td>{{ item.nbInvoicedSites }}/{{ item.nbSites }}</td>
            <td>{{ item.nbInvoicedClusters }}</td>
            <td>{{ item.millionRequestsPerMonth }}</td>
            <td>{{ item.nbOrganizations }}</td>
            <td>
              <app-timestamp-cell [value]="item.createdAt"></app-timestamp-cell>
            </td>
            <td>
              <app-timestamp-cell [value]="item.startDate"></app-timestamp-cell>
            </td>
            <td class="actions">
              <a [routerLink]="[item.id]"> <i class="icofont icofont-edit-alt" title="Editer"></i></a>
              <i (click)="modalRemove(item)" class="icofont icofont-ui-delete" title="Supprimer"></i>
            </td>
          </tr>
        </ng-template>
      </tbody>
    </table>
    <div class="float-right mr-2">
      <pagination-controls
        class="pull-right"
        (pageChange)="changePage($event)"
        previousLabel="{{ 'Previous' | translate }}"
        nextLabel="{{ 'Next' | translate }}"
      ></pagination-controls>
      <div class="pull-right mr-4 total-admin">Total : {{ totalItems }}</div>
    </div>
  </div>
  <div *ngIf="contracts.length == 0">{{ 'Contract.none' | translate }}</div>
</div>

<!-- POPUP REMOVE contract -->
<app-modal-basic #remove [dialogClass]="'modal-md'">
  <div class="app-modal-header">
    <h4 class="modal-title">
      <small [innerHtml]="'Confirmation'"></small>
    </h4>
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="label label-primary">{{ 'Contract.number' | translate }}</label>
          <p>{{ contract?.number }}</p>
        </div>
        <div class="form-group">
          <label class="label label-primary">{{ 'Contract.holder' | translate }}</label>
          <p>{{ contract?.holder?.companyName }} / {{ contract?.holder?.code }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button (click)="removeContract(contract?.id)" class="btn btn-sm btn-primary" [innerHtml]="'Supprimer'"></button
    >&nbsp;
    <button (click)="remove.hide()" class="btn btn-sm btn-danger" [innerHtml]="'Annuler'"></button>
  </div>
</app-modal-basic>
