<div class="col-12">
  <div class="card">
    <div class="card-header" style="padding-bottom: 0">
      <h4 class="modal-title">
        {{ (mode == EditMode.UPDATE ? 'EditOrganization' : 'AddOrganization') | translate }}
        <span class="badge badge-sm bg-dark"
          ><a style="color: inherit" target="_blank" [href]="'ExternalHelp.organizations' | translate">?</a></span
        >
      </h4>
      <p *ngIf="!fromAdmin" style="margin-top: 10px">{{ 'AddAccountTooltip' | translate }}</p>
      <hr />
    </div>
    <div *ngIf="organization" class="card-body" style="padding-top: 0">
      <div class="row">
        <div class="col-xl-6">
          <div *ngIf="fromAdmin && mode == EditMode.UPDATE" class="form-group">
            <label [innerHtml]="'Code' | translate"></label>
            <app-badge-organization [code]="organization.code" class="form-control" />
          </div>
          <div class="form-group">
            <label class="star" [innerHtml]="'Nom' | translate"></label>
            <input [(ngModel)]="organization.companyName" type="text" class="form-control" />
          </div>
          <div *ngIf="fromAdmin" class="form-group">
            <label>{{ 'UseDelegations' | translate }}</label>
            <ng-select
              (change)="filterAvailableCluster()"
              [items]="availableClusters"
              [multiple]="true"
              [closeOnSelect]="true"
              bindLabel="nom"
              placeholder="Clusters"
              [(ngModel)]="clustersRead"
              autocomplete="off"
            ></ng-select>
          </div>
          <div *ngIf="fromAdmin" class="bg-orange form-group">
            <p>
              <span class="warn-sign">⚠️</span><b>{{ 'WarningAdminDeleg' | translate }}</b>
            </p>
            <div class="mb-2 form-group">
              <label>{{ 'AdminDelegations' | translate }}</label>
              <ng-select
                (change)="filterAvailableCluster()"
                [items]="availableClusters"
                [multiple]="true"
                [closeOnSelect]="true"
                bindLabel="nom"
                placeholder="Clusters"
                [(ngModel)]="clustersAdmin"
                autocomplete="off"
              ></ng-select>
            </div>
          </div>
          <div *ngIf="fromAdmin" class="form-group">
            <label class="star" [innerHtml]="'AdresseMailOwner' | translate"></label>
            <input [(ngModel)]="organization.owner.email" type="text" class="form-control" />
          </div>
        </div>
        <div class="col-xl-6">
          <div class="form-group">
            <label [innerHtml]="'Pays' | translate"></label>
            <select name="countries" class="form-control" [(ngModel)]="organization.countryCode">
              <option *ngFor="let p of countries" [value]="p.code">{{ p.name }}</option>
            </select>
          </div>
          <div class="form-group">
            <label [innerHtml]="'Ville' | translate"></label>
            <input [(ngModel)]="organization.city" type="text" class="form-control" />
          </div>
          <div class="form-group">
            <label [innerHtml]="'CodePostal' | translate"></label>
            <input [(ngModel)]="organization.zipCode" type="text" class="form-control" />
          </div>
          <div class="form-group">
            <label [innerHtml]="'Rue' | translate"></label>
            <input [(ngModel)]="organization.street" type="text" class="form-control" />
          </div>
        </div>
        <div *ngIf="mode == EditMode.UPDATE" class="col-md-12 mt-3">
          <app-admin-organization-users [organizationCode]="organization.code" [organizationId]="organization.id" />
        </div>
        <div *ngIf="mode == EditMode.UPDATE && organization.code" class="col-md-12 mt-3">
          <app-organization-contracts [organizationCode]="organization.code" />
        </div>
      </div>
    </div>
    <div *ngIf="organization" class="card-footer">
      <button
        *ngIf="mode == EditMode.CREATE"
        [disabled]="!canValidate()"
        (click)="createOrganization(organization)"
        class="btn btn-sm btn-primary mr-2"
        [innerHtml]="'ajouter' | translate"
      ></button>
      <button
        *ngIf="mode == EditMode.UPDATE"
        [disabled]="!canValidate()"
        (click)="updateOrganization(organization)"
        class="btn btn-sm btn-primary mr-2"
        [innerHtml]="'Enregistrer' | translate"
      ></button>
      <button
        (click)="organization = emptyOrganization(); back()"
        class="btn btn-sm btn-danger"
        [innerHtml]="'Annuler' | translate"
      ></button>
    </div>
  </div>
</div>
