import { SitesService } from 'app/services/sites.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { destroyGrooveHQWidget } from '../groovehq';
import { DASHBOARD_ROUTE } from '../app-routing.module';
import { firstValueFrom, Observable } from 'rxjs';
import { getPageParams, PageRequest, SpringPage } from 'app/shared/page';
import { Organization } from './entities/organization';
import { Contract } from './entities/contract';

@Injectable({
  providedIn: 'root',
})
export class AdminOrganizationService {
  constructor(private http: HttpClient) {}

  getOrganization$(organizationCode: string): Observable<Organization> {
    return this.http.get<Organization>(`admin/organizations/${organizationCode}`);
  }

  async getOrganizations(query: OrganizationQuery, page = null): Promise<SpringPage<Organization>> {
    return firstValueFrom(this.getOrganizations$(query, page));
  }

  getOrganizations$(query: OrganizationQuery, page = 0): Observable<SpringPage<Organization>> {
    let params = new HttpParams().set('page', page);
    if (query?.searchTerm)
      params = params.appendAll({
        companyName: query.searchTerm,
        ownerEmail: query.searchTerm,
        ownerLastName: query.searchTerm,
        ownerFirstName: query.searchTerm,
        code: query.searchTerm,
      });
    if (query?.ownsSite) params = params.set('ownsSite', query.ownsSite);

    return this.http.get<SpringPage<Organization>>(`admin/organizations`, { params });
  }

  public async getOrganizationUsers(
    idOrCode,
    page = 0,
    size = 10,
    searchTerm = '',
  ): Promise<SpringPage<OrganizationUser>> {
    let url = `admin/organizations/${idOrCode}/users?page=${page}&size=${size}`;
    if (searchTerm) url += `&email=${searchTerm}`;
    return firstValueFrom(this.http.get<SpringPage<OrganizationUser>>(url));
  }

  async getOrganizationContracts(organizationCode: string, page: PageRequest = null): Promise<SpringPage<Contract>> {
    return firstValueFrom(
      this.http.get<SpringPage<Contract>>(`admin/organizations/${organizationCode}/contracts`, {
        params: getPageParams(page),
      }),
    );
  }

  async changeSiteOrganizationOwner(siteName: string, organizationId: string): Promise<Object> {
    return firstValueFrom(this.http.put(`admin/sites/${siteName}/owner`, { id: organizationId }));
  }

  async deleteOrganization(organizationId): Promise<Object> {
    return firstValueFrom(this.http.delete(`admin/organizations/${organizationId}`));
  }
}

interface OrganizationQuery {
  searchTerm?: string;
  ownsSite?: boolean;
}

export interface OrganizationListItem {
  id: number;
  code: string;
  companyName: string;
  street: string;
  zipCode: string;
  city: string;
  countryCode: string;
  owner: {
    id: any;
    email: string;
    firstName: string;
    lastName: string;
  };

  createdAt: number;
  updatedAt: number;
  nbSites: number;
}

export type OrganizationUser = {
  user: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    isActive: boolean;
  };
  role: string;
};
