import { Injectable } from '@angular/core';
import { AbstractOrganizationService } from './abstractOrganizationService';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { SiteMode } from '../shared/hostMode/hostMode.service';

@Injectable({
  providedIn: 'root',
})
export class OrganizationClusterService extends AbstractOrganizationService {
  constructor(
    protected http: HttpClient,
    protected auth: AuthService,
  ) {
    super(http, auth);
  }

  public getClusters(): Observable<OrganizationCluster[]> {
    return this.http.get<OrganizationCluster[]>(this.getOrganizationEndpoint() + '/clusters');
  }
}

export type OrganizationCluster = {
  cluster: Cluster;
  role: ClusterRole;
};

export type Cluster = {
  id: number;
  clusterId: string;
  name: string;
  ip: string;
  ipv6: string;
  cdnName: string;
  supportsIpv6Origins: boolean;
  supportsCache: boolean;
  supportedCdns: string[];
  ipsToWhitelist: string[];
  isPublic: boolean;
  clusterTypeId: number;
  defaultSiteMode: SiteMode;
  logExport: boolean;
  owner: { companyName: string };
};

export type ClusterRole = 'USE' | 'ADMIN' | 'OWNER';
