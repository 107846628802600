import { Injectable } from '@angular/core';
import { AbstractOrganizationService } from './abstractOrganizationService';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Rule, Site } from './entities/site';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Audit } from './entities/audit';
import _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class OrganizationSiteService extends AbstractOrganizationService {
  constructor(
    protected http: HttpClient,
    protected auth: AuthService,
  ) {
    super(http, auth);
  }

  public getSite(siteRef: SiteRef): Observable<Site> {
    return this.http.get<Site>(this.siteUrl(siteRef));
  }

  public patchSite(siteRef: SiteRef, request: PatchSiteRequest): Observable<Site> {
    return this.http.patch<Site>(this.siteUrl(siteRef), request);
  }

  public getTags(siteRef: SiteRef): Observable<string[]> {
    return this.http.get<{ tags: string[] }>(this.siteUrl(siteRef) + '/tags').pipe(map((body) => body.tags));
  }

  getCertificates(siteRef: SiteRef): Observable<Certificate[]> {
    return this.http.get<Certificate[]>(this.siteUrl(siteRef) + '/certificates');
  }

  getBlacklistedCountries(siteRef: SiteRef): Observable<string[]> {
    return this.http.get<string[]>(this.siteUrl(siteRef) + '/blacklisted-countries');
  }

  setBlacklistedCountries(siteRef: SiteRef, blacklistedCountries: string[]): Observable<void> {
    return this.http.put<void>(this.siteUrl(siteRef) + '/blacklisted-countries', blacklistedCountries);
  }

  getWhitelistedIps(siteRef: SiteRef): Observable<any[]> {
    return this.http.get<string[]>(this.siteUrl(siteRef) + '/whitelisted-ips');
  }

  getUrlExceptions(siteRef: SiteRef): Observable<UrlException[]> {
    return this.http.get<UrlException[]>(this.siteUrl(siteRef) + '/url-exceptions');
  }

  getRules(siteRef: SiteRef): Observable<(Rule & Audit)[]> {
    return this.http.get<(Rule & Audit)[]>(this.siteUrl(siteRef) + '/rules');
  }

  createRule(siteRef: SiteRef, rule: Omit<Rule, 'id'>) {
    return this.http.post<Rule>(this.siteUrl(siteRef) + `/rules`, rule);
  }

  updateRule(siteRef: SiteRef, rule: Rule) {
    return this.http.put(this.siteUrl(siteRef) + `/rules/${rule.id}`, _.omit(rule, 'id'));
  }

  deleteRule(siteRef: SiteRef, ruleId: number) {
    return this.http.delete(this.siteUrl(siteRef) + `/rules/${ruleId}`);
  }

  share(siteRef: SiteRef, recipient, role) {
    return this.http.put<SiteShare>(this.siteUrl(siteRef) + `/shares/${recipient}`, { role });
  }

  private siteUrl(siteRef: SiteRef) {
    return this.sitesUrl() + '/' + siteRef;
  }

  private sitesUrl() {
    return this.getOrganizationEndpoint() + '/sites';
  }
}

type SiteRef = number | string;

export type SiteShare = {
  role: string;
  recipient: {
    code: string;
    companyName: string;
  };
};

type PatchSiteRequest = Partial<{
  cacheEnabled: boolean;
  cdn: string;
}>;

type Certificate = {
  id: number;
  csr: string;
  type: 'LE' | 'CE';
  cn: string;
  fullChainCert: string;
  expiredAt: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  error: string;
};

type UrlException = {
  id: number;
  path: string;
  comment: string;
};
