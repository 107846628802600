import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { ADMIN_CONTEXT, BaseLogRequest, ContextEnum } from 'app/services/sites.service';
import { BANDWIDTH, GraphType, RESPONSE_TIME, RESPONSES_CODES, TRAFIC } from 'app/shared/highcharts/graph/graph';
import { Page } from 'app/shared/page';
import { buildHttpParams } from 'app/shared/utils/request-utils';
import { prependIfMissing } from 'app/shared/utils/strings';
import _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { FiltersEnum } from '../theme/my-logs/filters/filters';
import { AbstractOrganizationService } from './abstractOrganizationService';
import { DateRange } from '../shared/utils/date-range';

export enum ActionEnum {
  AUTHORIZED = 'authorized',
  SUSPICIOUS = 'suspicious',
  BLOCKED = 'blocked',
}

export enum CauseEnum {
  ANALYZED_OK = 'analyzedOK',
  PASSTHROUGH_RULE = 'passthroughRule',
  WHITELISTED_IP = 'whitelistedIp',
  URL_EXCEPTION = 'urlException',
  ANALYZED_KO = 'analyzedKO',
  GEO_BLOCKED = 'geoBlocked',
  BLOCKED_BY_RULE = 'blockedByRule',
  BANNED = 'banned',
  CDN_HIT = 'cdnHit',
  CDN_BLOCKED = 'cdnBlocked',
}

export enum LogFiltersType {
  IN = 'in',
  NOTIN = 'notIn',
  FLAT = 'FLAT',
  LTE = 'lte',
  GTE = 'gte',
}

export type LogRequest = BaseLogRequest;

export interface AttackCategory {
  id: number;
  name: string;
  hidden: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class LogsService extends AbstractOrganizationService {
  private cancelPendingRequests$ = new Subject<void>();

  constructor(
    http: HttpClient,
    auth: AuthService,
    private router: Router,
  ) {
    super(http, auth);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.cancelPendingRequests$.next();
      }
    });
  }

  getLogs(request: LogRequest, pageParams: PageParams, context: ContextEnum): Observable<Page<LogEntry>> {
    return this.http
      .get<SpringPage<any>>(this.getLogsEndpoint('', context), {
        params: buildHttpParams({ ...pageParams, ...request }),
      })
      .pipe(takeUntil(this.cancelPendingRequests$))
      .pipe(
        map((logs) => ({
          totalItems: logs.totalElements,
          content: logs.content.map((log) => this.parseLog(log)),
        })),
      );
  }

  downloadLogs(request: LogRequest, context: ContextEnum): Observable<Blob> {
    const params = buildHttpParams(request);

    return this.http
      .get(this.getLogsEndpoint('download', context), {
        params,
        reportProgress: true,
        observe: 'response',
        responseType: 'blob' as 'json',
      })
      .pipe(map((res) => new Blob([res.body as any], { type: 'json' })));
  }

  getAttacksCategories(): Observable<AttackCategory[]> {
    return this.http.get<AttackCategory[]>('v2/attack-categories');
  }

  getBlockedByBrain(request: LogRequest, context: ContextEnum): Observable<DatasWithTotal<DrillDown>> {
    return this.http
      .get<DatasWithTotal<DrillDown>>(this.getLogsEndpoint('by-attack-category', context), {
        params: buildHttpParams(request),
      })
      .pipe(takeUntil(this.cancelPendingRequests$));
  }

  getTopBlockedIps(request: LogRequest & Partial<AggSize>, context: ContextEnum): Observable<BlockedIp[]> {
    return this.http
      .get<BlockedIpData[]>(this.getLogsEndpoint('top-blocked-ips', context), { params: buildHttpParams(request) })
      .pipe(takeUntil(this.cancelPendingRequests$))

      .pipe(
        map((datas) =>
          _.flatMap(datas, (blockedIp) =>
            blockedIp.siteStats.map((siteStats) => ({
              ip: blockedIp.ip,
              countryCode: blockedIp.countryCode,
              ...siteStats,
            })),
          ),
        ),
      );
  }

  getSlowUrls(request: LogRequest & Partial<AggSize>, context: ContextEnum): Observable<UrlStats[]> {
    return this.http
      .get<UrlStats[]>(this.getLogsEndpoint('slow-urls', context), { params: buildHttpParams(request) })
      .pipe(takeUntil(this.cancelPendingRequests$));
  }

  getCpuConsumingUrls(request: LogRequest & Partial<AggSize>, context: ContextEnum): Observable<UrlStats[]> {
    return this.http
      .get<UrlStats[]>(this.getLogsEndpoint('cpu-consuming-urls', context), {
        params: buildHttpParams(request),
      })
      .pipe(takeUntil(this.cancelPendingRequests$));
  }

  getDateHistograms<T extends keyof any, D>(
    graphType: GraphType,
    request: DateHistogramLogRequest,
    context: ContextEnum,
  ): Observable<DateHistograms<T, D>> {
    const DateHistogramEndpoints: { [key in GraphType]: string } = {
      // have to do this otherwise it was buggy
      [TRAFIC]: 'traffic',
      [RESPONSE_TIME]: 'response-time',
      [RESPONSES_CODES]: 'response-code',
      [BANDWIDTH]: 'bandwidth',
    };

    return this.http
      .get<DateHistograms<T, D>>(this.getLogsEndpoint(DateHistogramEndpoints[graphType], context), {
        params: buildHttpParams(request),
      })
      .pipe(takeUntil(this.cancelPendingRequests$))
      .pipe(
        map(
          (res) =>
            // TODO remove key mapping
            _.mapValues(res, (histogram) =>
              _.mapKeys(histogram, (_, k) => (k == 'dateHistogram' ? 'data' : k)),
            ) as DateHistograms<T, D>,
        ),
      );
  }

  getTrafficByCountry(request: LogRequest, context: ContextEnum): Observable<TrafficByCountry> {
    return this.http
      .get<TrafficByCountry>(this.getLogsEndpoint('traffic/by-country', context), {
        params: buildHttpParams(request),
      })
      .pipe(takeUntil(this.cancelPendingRequests$));
  }

  getTrafficBreakdown(
    suffix: string,
    request: LogRequest,
    context: ContextEnum,
  ): Observable<(SiteTrafficBreakdown & Rps)[]> {
    const periodDuration = new DateRange(request.beginDate, request.endDate).length('seconds');
    return this.getBreakdown<SiteTrafficBreakdown>(context, suffix, request).pipe(
      map((items) => items.map((item) => ({ ...item, rps: item.total / periodDuration }))),
    );
  }

  getUrlBreakdown<T>(suffix: string, request: LogRequest, context: ContextEnum): Observable<T[]> {
    return this.getBreakdown<T>(context, suffix, request);
  }

  private getBreakdown<T>(context: ContextEnum, suffix: string, request: LogRequest): Observable<T[]> {
    return this.http
      .get<T[]>(this.getLogsEndpoint(suffix, context), {
        params: buildHttpParams(request),
      })
      .pipe(takeUntil(this.cancelPendingRequests$));
  }

  private parseLog(data: any): LogEntry {
    let {
      dateMilli,
      action,
      cause,
      rulePriority,
      dryRun,
      drives,
      ipReputation,
      rewriteOriginalPath,
      rt,
      mainAttack,
      mainAttackLabel,
      ...rest
    } = data;

    rest.responseTimeMs = Math.trunc(rest.responseTimeMs * 100) / 100;

    // uncategorized requests
    if (data.action == null && data.cause == null) {
      data.action = ActionEnum.AUTHORIZED;
      data.cause = CauseEnum.ANALYZED_OK;
    }

    const log: any = {
      site: data.site,
      path: data.requestInfo['request-uri'],
      date: data.dateMilli,
      clientIP: data.clientIP,
      time: data.httpResponseMilli,
      method: data.requestInfo['method'],
      code: data.responseCode,
      countryCode: data.countryCode,
      action: data.action,
      cause: data.cause,
      cache: data.cache,
      dryRun: data.dryRun,
      rulePriority: data.rulePriority,
      drivesList: drives ? this.parseDrives(drives) : null,
      rewriteOriginalPath,
      mainAttack,
      rt: rt / 1000,
      url: data.url,
      responseTimeMs: rest.responseTimeMs,
      contentLength: data.contentLength,
      requestDetails: { ...rest },
    };

    if (
      _.isNumber(ipReputation) &&
      ipReputation >= 0 // TODO: remove this check when backend does not return -1 anymore
    ) {
      rest.credibility = Math.trunc(ipReputation / FACTOR_FILTER_MAP[FiltersEnum.CREDIBILITY_BETWEEN]) + '%';
      log.credibility = ipReputation;
    }

    if (log?.requestDetails?.requestInfo['request-uri']) {
      log.requestDetails.requestInfo.path = log.requestDetails.requestInfo['request-uri'];
      delete log.requestDetails.requestInfo['request-uri'];
    }

    return log;
  }

  private parseDrives(drivesList) {
    let highlight = [];
    let drives = [];

    drivesList.forEach((d) => {
      let q = d.type;
      let p = d.param;
      drives.push({
        category: d.label.category,
        label: d.label.label,
        description: d.label.description,
      });

      switch (q) {
        case 'i':
          if (p == 'path-info') {
            p = 'request-uri';
          }
          highlight.push(`requestInfo.${p.toLowerCase()}`);
          break;
        case 'h':
          highlight.push(`requestHeaders.${p.toLowerCase()}`);
          break;
        case 'c':
          highlight.push(`requestHeaders.cookie}`);
        case 'q':
          highlight.push('requestInfo.query-string');
          break;
        case 'b':
          highlight.push('body');
          break;
      }
    });

    return { drives, highlight };
  }

  private getLogsEndpoint(suffix: string, context: ContextEnum): string {
    return (
      this.getOrganizationEndpoint() +
      '/logs' +
      (context == ADMIN_CONTEXT ? '-admin-cluster' : '') +
      (suffix !== '' ? prependIfMissing(suffix, '/') : '')
    );
  }
}

export const FACTOR_FILTER_MAP = {
  [FiltersEnum.CREDIBILITY_BETWEEN]: 1000,
};

export type DateHistogram = { data: Array<[number, number]> };

export type DateHistograms<T extends keyof any, D> = { [key in T]: DateHistogram & D };

export type TrafficByCountry = Record<
  'traffic' | 'attack' | 'robot' | 'blockedCountry',
  Array<{ name: string; y: number }>
>;

export type DateHistogramLogRequest = LogRequest & { aggregationTimeBound: string };

export type CacheStatus = 'HIT' | 'HIT_CDN' | 'MISS' | 'NONE';

export type BandwidthHistograms = {
  contentLength: DateHistograms<string, {}>;
  cacheStatus: DateHistograms<CacheStatus, {}>;
};

export type ResponseTimeHistograms = {
  traffic: DateHistogram;
  responseTime: DateHistograms<string, {}>;
};

interface BlockedIpData {
  ip: string;
  countryCode: string;
  blocked: number;
  siteStats: BlockedIpSiteData[];
}

interface BlockedIpSiteData {
  site: string;
  total: number;
  blockedByBrain: number;
  blockedByRule: number;
  geoblocked: number;
  suspicious: number;
}

export type BlockedIp = { ip: string; countryCode: string } & BlockedIpSiteData;

export interface DatasWithTotal<T> {
  datas: T[];
  total: number;
}

export interface DrillDown {
  id: number;
  name: String;
  drillDown: string;
  y: number;
}

export interface PageParams {
  index: number;
  size: number;
}

export interface LogEntry {
  site: string;
  path: string;
  url: string;
  method: string;
  code: string;
  date: number;
  clientIP: string;
  countryCode: string;
  time: number;
  responseTimeMs: number;
  cause: string;
  dryRun: boolean;
  rulePriority: number | string;
  cache: string;
}

export interface UrlStats {
  url: string;
  site: string;
  path: string;
  count: number;
  avg: number;
  min: number;
  max: number;
  sum: number;
}

export interface UrlBandwidth {
  url: string;
  bandwidth: { [prop in CacheStatus]: number };
}

export interface UrlResponseCodesCount {
  url: string;
  count: number;
  count4xx: number;
  count5xx: number;
}

export interface AggSize {
  aggSize: number;
}

interface SpringPage<T> {
  totalElements: number;
  content: T[];
}

export interface SiteTrafficBreakdown {
  site: string;
  total: number;
  blocked: number;
  suspect: number;
}
export interface Rps {
  rps: number;
}
