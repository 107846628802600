import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import { AuthService, MFA } from '../../../services/auth.service';
import { validatePasswordRestrictions } from '../../../shared/validators/validators';
import { Lang } from '../../../shared/utils/lang';

@Component({
  selector: 'app-my-parameters',
  templateUrl: './my-parameters.component.html',
  styleUrls: ['./my-parameters.component.scss', '../../../../assets/icon/icofont/css/icofont.scss'],
  standalone: false,
})
export class MyParametersComponent implements OnInit {
  lang: Lang;

  @ViewChild('profileForm') public editProfileForm: NgForm;

  user: User;
  editField: boolean;

  constructor(
    private auth: AuthService,
    private http: HttpClient,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {
    this.lang = this.auth.getCurrentLanguage();
  }

  ngOnInit() {
    this.getUser();
    this.editField = false;
  }

  onEditField = () => {
    this.editField = true;
  };

  updateUser = () => {
    const data = { ...this.user };

    this.http.put('v2/my-profile', data).subscribe({
      next: (res) => {
        this.getUser();
        this.toastr.success(this.translate.instant('SuccfChang'));
        this.editField = false;
      },
      error: (err) => {
        this.toastr.error(this.translate.instant('VeuillezRenseignerTousLesChamps'));
      },
    });
  };

  cancelEditUser = () => {
    this.getUser();
    this.editField = false;
  };

  getUser() {
    this.http.get<User>('v2/my-profile').subscribe((user) => {
      this.user = user;
      this.auth.updateUser({ ...user, lang: this.lang });
    });
  }
}

type User = EditableUser & {
  hasApiKey: boolean;
};

type EditableUser = {
  email: string;
  firstName: string;
  lastName: string;
  lang: Lang;
  mfa: MFA;
};
