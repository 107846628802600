import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import { copyObject, subnet } from '../../../shared/utils/data-utils';
import { AuthService } from 'app/services/auth.service';
import { validateIp } from '../../../shared/validators/validators';
import { prependIfMissing } from '../../../shared/utils/strings';
import { OrganizationSiteService } from '../../../services/organization-site.service';
import { Site } from '../../../services/entities/site';

@Component({
  selector: 'app-exceptions',
  templateUrl: './exceptions.component.html',
  styleUrls: [
    './exceptions.component.scss',
    '../../../../assets/icon/icofont/css/icofont.scss',
    '../add-site.component.scss',
  ],
  standalone: false,
})
export class ExceptionsComponent implements OnInit {
  lang: string;

  @Input({ required: true }) site: Site;

  urlExceptions: UrlException[] = [];
  whitelistedIps: WhitelistedIp[] = [];

  tmpUrlException: UrlException;
  tmpWhitelistedIp: any = {};

  @ViewChild('urlExceptionElement') urlExceptionElement: ElementRef;
  @ViewChild('whitelistedIpElement') whitelistedIpElement: ElementRef;

  constructor(
    private http: HttpClient,
    private siteApi: OrganizationSiteService,
    private toastr: ToastrService,
    private auth: AuthService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.lang = this.auth.getCurrentLanguage();

    this.loadUrlExceptions();
    this.loadWhitelistedIps();
  }

  loadUrlExceptions() {
    this.urlExceptions = [];
    this.siteApi.getUrlExceptions(this.site.name).subscribe({
      next: (res: any[]) => {
        this.urlExceptions = (res || []).reverse();
      },
      error: (error: any) => {
        this.toastr.error('Failed to load URL exceptions');
        console.error('Error : ', error);
      },
    });
  }

  loadWhitelistedIps() {
    this.whitelistedIps = [];
    this.siteApi.getWhitelistedIps(this.site.name).subscribe({
      next: (res) => {
        this.whitelistedIps = res.reverse();
      },
      error: (error: any) => {
        this.toastr.error('Failed to load whitelisted IPs');
        console.error('Error : ', error);
      },
    });
  }

  addUrlException() {
    this.urlExceptions.push({ path: '/', comment: '' });
    setTimeout(() => this.urlExceptionElement.nativeElement.focus(), 1);
  }

  isCreatingUrlException() {
    return this.urlExceptions.some((rule) => !rule.id);
  }

  createUrlException(urlException: UrlException) {
    const path = urlException.path.trim();
    if (this.urlExceptions.find((u) => u.path == path && u.id)) {
      return this.toastr.error(this.translate.instant('AlreadyExists'));
    }

    this.http.post(this.getEndpoint(), { path: path, comment: urlException.comment }).subscribe({
      next: (res: any) => {
        this.urlExceptions.splice(
          this.urlExceptions.findIndex((u) => !u.id),
          1,
        );
        this.urlExceptions.push(res);
        this.toastr.success(this.translate.instant('EnregistrementReussi'));
        this.addUrlException();
      },
      error: () => {
        this.toastr.error(this.translate.instant('EnregistrementEchoue'));
      },
    });
  }

  deleteUrlException(urlException: UrlException) {
    if (urlException.editing) {
      urlException.editing = false;
      urlException.path = this.tmpUrlException.path;
      urlException.comment = this.tmpUrlException.comment;
      return;
    } else if (!urlException.id) {
      this.urlExceptions.splice(
        this.urlExceptions.findIndex((u) => !u.id),
        1,
      );
    }

    if (urlException.id) {
      this.http.delete(this.getEndpoint(urlException.id)).subscribe({
        next: () => {
          this.loadUrlExceptions();
          this.toastr.success();
        },
        error: () => {
          this.toastr.error();
        },
      });
    } else {
      this.urlExceptions.filter((r) => r.id);
    }
  }

  startUrlExceptionEditing(urlException: UrlException) {
    this.tmpUrlException = copyObject(urlException); // deep copy in order to restore if cancelling editing
    urlException.editing = true;
    setTimeout(() => this.urlExceptionElement.nativeElement.focus(), 1);
  }

  validateUrlExceptionEditing(urlException: UrlException) {
    if (urlException.path) {
      urlException.editing = false;

      const request = {
        path: urlException.path,
        comment: urlException.comment,
      };

      this.http.put(this.getEndpoint(urlException.id), request).subscribe({
        next: (res: any) => {
          this.loadUrlExceptions();
          this.toastr.success(this.translate.instant('OperationSuccess'));
        },
        error: () => {
          this.toastr.error(this.translate.instant('OperationFailed'));
        },
      });
    }
  }

  isEditingUrlException() {
    return this.urlExceptions.some((urlException) => urlException.editing);
  }

  handleEnterUrlException(event, urlException) {
    if (event.keyCode === 13 && !event.shiftKey) {
      urlException.editing ? this.validateUrlExceptionEditing(urlException) : this.createUrlException(urlException);
      event.preventDefault();
    } else if (event.keyCode === 27) {
      this.deleteUrlException(urlException);
    }
  }

  addWhitelistedIp() {
    this.whitelistedIps.push({
      new: true, // front only
      ip: '',
      comment: '',
    });
    setTimeout(() => this.whitelistedIpElement.nativeElement.focus(), 1);
  }

  createWhitelistedIp(whitelistedIp: WhitelistedIp) {
    let ip = whitelistedIp.ip.trim();

    if (!validateIp(ip)) {
      this.toastr.error(this.translate.instant('WrongIp'));
      return;
    }

    if (this.whitelistedIps.find((w) => w.ip == ip && !w.new)) {
      return this.toastr.error(this.translate.instant('AlreadyExists'));
    }

    const request = {
      datas: [
        {
          hoteId: this.site.id,
          ip: subnet(ip),
          comment: whitelistedIp.comment,
        },
      ],
    };

    this.http.post('whitelistedIp/create', request).subscribe((res: any) => {
      if (!res.hasError) {
        this.whitelistedIps.splice(
          this.whitelistedIps.findIndex((w) => w.new),
          1,
        );
        this.whitelistedIps.push(res.items[0]);
        this.toastr.success(this.translate.instant('IPWellAddedtoWhitelist'));
        this.addWhitelistedIp();
      } else {
        this.toastr.error(this.translate.instant('EnregistrementEchoue'));
      }
    });
  }

  deleteWhitelistedIp(whitelistedIp) {
    if (whitelistedIp.editing) {
      whitelistedIp.editing = false;
      whitelistedIp.ip = this.tmpWhitelistedIp.ip;
      whitelistedIp.comments = this.tmpWhitelistedIp.comments;
      return;
    }

    if (whitelistedIp.id) {
      const request = {
        datas: [
          {
            hoteId: this.site.id,
            ip: whitelistedIp.ip,
          },
        ],
      };
      this.http.post('whitelistedIp/delete', request).subscribe((res: any) => {
        if (!res.hasError) {
          this.loadWhitelistedIps();
          this.toastr.success(this.translate.instant('OperationSuccess'));
        } else {
          this.toastr.error(this.translate.instant('OperationFailed'));
        }
      });
    } else {
      this.whitelistedIps.splice(
        this.whitelistedIps.findIndex((w) => w.new),
        1,
      );
    }
  }

  startWhitelistedIpEditing(whitelistedIp: WhitelistedIp) {
    this.tmpWhitelistedIp = copyObject(whitelistedIp); // deep copy in order to restore if cancelling editing
    whitelistedIp.editing = true;
    setTimeout(() => this.whitelistedIpElement.nativeElement.focus(), 1);
  }

  validateWhitelistedIpEditing(whitelistedIp: WhitelistedIp) {
    if (whitelistedIp.ip) {
      whitelistedIp.editing = false;

      const request = {
        datas: [
          {
            hoteId: this.site.id,
            ip: whitelistedIp.ip,
            id: whitelistedIp.id,
            comment: whitelistedIp.comment,
          },
        ],
      };

      this.http.post('whitelistedIp/update', request).subscribe((res: any) => {
        if (!res.hasError) {
          this.loadWhitelistedIps();
          this.toastr.success(this.translate.instant('OperationSuccess'));
        } else {
          this.toastr.error(this.translate.instant('OperationFailed'));
        }
      });
    }
  }

  isEditingWhitelistedIp() {
    return this.whitelistedIps.some((whitelistedIp) => whitelistedIp.editing);
  }

  isCreatingWhitelistedIp() {
    return this.whitelistedIps.some((rule) => rule.new);
  }

  handleEnterWhitelistedIp(event, whitelistedIp) {
    if (event.keyCode === 13 && !event.shiftKey) {
      whitelistedIp.editing
        ? this.validateWhitelistedIpEditing(whitelistedIp)
        : this.createWhitelistedIp(whitelistedIp);
      event.preventDefault();
    } else if (event.keyCode === 27) {
      this.deleteWhitelistedIp(whitelistedIp);
    }
  }

  private getEndpoint(suffix?: any): string {
    return (
      'v2/organizations/' +
      this.auth.currentOrganization.id +
      '/sites/' +
      this.site.name +
      '/url-exceptions' +
      (suffix ? prependIfMissing('' + suffix, '/') : '')
    );
  }
}

interface UrlException {
  editing?: boolean;
  id?: number;
  path: string;
  comment?: string;
}

interface WhitelistedIp {
  editing?: boolean;
  new: boolean;
  id?: number;
  ip: string;
  comment?: string;
}
