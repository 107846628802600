import { Injectable } from '@angular/core';
import { AbstractOrganizationService } from './abstractOrganizationService';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Rule, Site } from './entities/site';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Audit, Timestamp } from './entities/audit';
import _ from 'lodash';
import { buildHttpParams } from '../shared/utils/request-utils';
import { PageParams } from './logs.service';
import { SpringPage } from '../shared/page';

@Injectable({
  providedIn: 'root',
})
export class OrganizationJournalService extends AbstractOrganizationService {
  constructor(
    protected http: HttpClient,
    protected auth: AuthService,
  ) {
    super(http, auth);
  }

  public getJournalEvents(query: Partial<JournalQuery>, pageParams: PageParams): Observable<SpringPage<JournalEvent>> {
    return this.http.get<SpringPage<JournalEvent>>(this.getOrganizationEndpoint() + '/journal', {
      params: buildHttpParams({ ...pageParams, ...query }),
    });
  }
}

export type JournalQuery = {
  clusterId: string;
  sites: string[];
  tags: string[];
  afterEvent: Timestamp;
  after: Timestamp;
  before: Timestamp;
  type: EventType;
  subtype: string;
  severity: EventSeverity;
  ip: string;
};

export enum EventSeverity {
  'LOW' = 1,
  'MEDIUM' = 2,
  'HIGH' = 3,
}

export type EventType = 'SECURITY' | 'MONITORING';

export type JournalEvent = {
  id: number;
  clusterId: string;
  site: string;
  type: EventType;
  subtype: string;
  severity: 1 | 2 | 3;
  date: Timestamp;
  userEmail: string;
  event: any;
};
