import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { destroyGrooveHQWidget } from '../groovehq';
import User, { Fonctionnalite } from './entities/user';
import { Organization } from './entities/organization';
import { UsersService } from './users.service';
import { registerLocaleData } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { AUTH_LOGIN_ROUTE, DASHBOARD_ROUTE } from '../app-routing.module';
import { Page } from 'app/shared/page';
import { sortAlphabetically } from 'app/shared/utils/data-utils';
import { asLangOrDefault, Lang } from '../shared/utils/lang';
import { OrganizationCluster } from './organization-cluster.service';
import { OgoResponse, OgoResponseItem } from './ogo-response';

export const USER_KEY_SESSION_STORAGE = 'user';
export const LANG_KEY_SESSION_STORAGE = 'lang';
export const MAP_USER_TO_FAVORITE_ORGANIZATION_KEY_SESSION_STORAGE = (userId) => `user-${userId}-favorite-organization`;

export const ACL_CRON = 'cron';
export const DEPRECATED_ACL_PROP_CLUSTER = 'prop-cluster';
export const ACL_SITE_EDIT = 'site_edit';
export const ACL_SITE_SHARE = 'site_share';
export const ACL_SITE_DELETE = 'site_delete';
export const ACL_CLUSTER_MANAGE = 'cluster_manage';
export const ACL_ORGANIZATION_MANAGE = 'organization_manage';
export const ACL_ORGANIZATION_USERS_MANAGE = 'user_manage';
export const ACL_PUSH_LOG_MANAGE = 'push_log_manage';
export const ACL_PUSH_LOG_ADD_SITE = 'export_logs';
export const ACL_ORGANIZATION_OWNER_MANAGE = 'edit_owner';

export const SHARE_ACCESS_RIGHT_ADMIN = 'ADMIN';
export const SHARE_ACCESS_RIGHT_VIEW = 'VIEW';
export const CLUSTER_DELEGATION_USE = 'USE';
export const CLUSTER_DELEGATION_ADMIN = 'ADMIN';

export const SHOW_API_DOC = 'SHOW_API_DOC';
export const SHOW_ADMIN_CLUSTER = 'SHOW_ADMIN_CLUSTER';
export const SHOW_ADMIN = 'voir-admin'; // come from backend

const LOCALE_DATAS: { [lang in Lang]: any } = {
  fr: localeFr,
  en: localeEn,
};

export const ACL_SITE_ACTIONS = {
  VIEW: 'VIEW',
  EDIT: 'EDIT',
  SHARE: 'SHARE',
  DELETE: 'DELETE',
  PUSH_LOG_SITE: 'EXPORT_LOG',
};

export type Features = typeof SHOW_ADMIN | typeof SHOW_API_DOC | typeof SHOW_ADMIN_CLUSTER;

interface UserOrganization {
  organization: { id: number; companyName: string; code: string };
  role: string;
  privileges: Array<string>;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public user: User;
  public organizations: { id: number; code: string; companyName: string }[] = [];
  public currentOrganization: Organization = new Organization({});

  constructor(
    private http: HttpClient,
    private router: Router,
    private usersService: UsersService,
    private translate: TranslateService,
  ) {}

  redirectUrl: string;
  isInit = false;

  public async login(email: string, password: string): Promise<OgoResponseItem<LoginResponse>> {
    this.purgeContext();

    return firstValueFrom(
      this.http.post<OgoResponseItem<LoginResponse>>('utilisateur/connexion', {
        data: {
          email: email,
          password: password,
        },
      }),
    );
  }

  public validateRegistrationToken(token: string, email: string) {
    return this.http.post<OgoResponseItem<ValidateRegistrationTokenResponse>>(
      'utilisateur/validateToken/registration',
      {
        data: { email, token },
      },
    );
  }

  public userRegistration(request: RegistrationRequest) {
    return this.http.post<OgoResponse>('utilisateur/registration', { data: request });
  }

  public async loginSuccess(user: LoginSuccess): Promise<void> {
    this.setUser(new User(user));
    if (this.redirectUrl) {
      this.router
        .navigateByUrl(this.redirectUrl)
        .then(() => {})
        .catch(() => this.router.navigate([DASHBOARD_ROUTE]));
      this.redirectUrl = null;
    } else {
      this.router.navigate([DASHBOARD_ROUTE]);
    }
  }

  public logout(): void {
    sessionStorage.clear();
    this.purgeContext();
    destroyGrooveHQWidget();
    this.router.navigate([AUTH_LOGIN_ROUTE]);
  }

  public isAuthenticated(): boolean {
    const user = JSON.parse(sessionStorage.getItem(USER_KEY_SESSION_STORAGE));
    if (user == null) {
      this.router.navigate([AUTH_LOGIN_ROUTE]);
      return false;
    }
    return true;
  }

  public getUser(): User {
    if (this.user) {
      return this.user;
    } else {
      const storedUser = JSON.parse(sessionStorage.getItem(USER_KEY_SESSION_STORAGE)) || {};
      console.log('User loaded from session storage', storedUser);
      return new User({
        ...storedUser,
        firstName: storedUser.firstName || storedUser.prenoms,
        lastName: storedUser.lastName || storedUser.nom,
      });
    }
  }

  public setUser(user?: User): User {
    this.user = user;

    sessionStorage.setItem(LANG_KEY_SESSION_STORAGE, user.lang);

    sessionStorage.setItem(USER_KEY_SESSION_STORAGE, JSON.stringify(user));

    return this.user;
  }

  public updateUser(updates: Partial<User>): User {
    return this.setUser(new User({ ...this.user, ...updates }));
  }

  public canProv(): boolean {
    return (this.currentOrganization?.clusters?.length || 0) > 0;
  }

  public setLanguage(lang: Lang): void {
    this.translate.use(lang);
    registerLocaleData(LOCALE_DATAS[lang], lang);
  }

  public userIsAdmin(): boolean {
    return this.getUser().profilCode == this.usersService.roles.admin;
  }

  public userHasFeature(code): boolean {
    return this.getUser().hasFeature(code);
  }

  public getCurrentLanguage(): Lang {
    const lang = sessionStorage.getItem(LANG_KEY_SESSION_STORAGE);
    return asLangOrDefault(lang);
  }

  public getApiKey(): Promise<Object> {
    return firstValueFrom(this.http.post('utilisateur/getApiKey', {}));
  }

  public async init(): Promise<void> {
    const user = this.getUser();
    this.setLanguage(user.lang);

    // get organizations list
    const userOrganizations = await this.loadOrganizations();

    // try to retrieve cached organization
    let organizationId = this.getCacheOrganizationId(user.id);
    let userOrganization = userOrganizations.find((uo) => uo.organization.id == organizationId);
    if (!userOrganization) {
      userOrganization = userOrganizations[0];
      organizationId = userOrganization.organization.id;
    }
    this.setCacheOrganizationId(user.id, organizationId);

    // get current organization detail
    const details: any = await firstValueFrom(this.http.get(`v2/organizations/${organizationId}`));
    this.currentOrganization = new Organization(details);
    this.currentOrganization.role = userOrganization.role;
    this.currentOrganization.privileges = userOrganization.privileges;

    // get current organizations clusters
    this.currentOrganization.clusters = await this.getOrganizationClusters(organizationId);

    // compute user ACL on the current organization
    if (!this.aclAlreadySet(user)) {
      this.computeACL(user, userOrganization.privileges);
    }

    // fetch organizations users if user is allowed
    if (user.hasFeature(ACL_ORGANIZATION_USERS_MANAGE)) {
      const users: any = await this.getOrganizationUsers(organizationId);
      this.currentOrganization.users = users.content;
      this.currentOrganization.nbUsers = users.totalElements;
    }

    // fetch pushLog conf if user is allowed
    if (user.hasFeature(ACL_PUSH_LOG_MANAGE)) {
      const pushLog: any = await this.getOrganizationPushLog(organizationId);
      if (pushLog.type != null && pushLog.configuration != null) this.currentOrganization.pushLog = pushLog;
    }

    this.isInit = true;
    this.setUser(user);
  }

  public async loadOrganizations(): Promise<UserOrganization[]> {
    const userOrganizations = await this.getOrganizations();
    this.organizations = userOrganizations
      .map((r) => r.organization)
      .sort((a, b) => sortAlphabetically(a?.companyName, b?.companyName));
    return userOrganizations;
  }

  private computeACL(user: User, organizationPrivileges): void {
    if (organizationPrivileges) {
      organizationPrivileges.forEach((f) => user.addFeature(f));
    }

    if (user.hasApiKey) {
      user.addFeature(SHOW_API_DOC);
    }

    if (
      user.hasFeature(ACL_CLUSTER_MANAGE) &&
      this.currentOrganization?.clusters.find((c) => c.role == CLUSTER_DELEGATION_ADMIN)
    ) {
      user.addFeature(SHOW_ADMIN_CLUSTER);
    }
  }

  private aclAlreadySet(user): boolean {
    return !!user.datasFonctionnalite.filter(
      (f) => ![SHOW_ADMIN, ACL_CRON, DEPRECATED_ACL_PROP_CLUSTER].includes(f.code),
    ).length;
  }

  private async getOrganizations(searchTerm = ''): Promise<UserOrganization[]> {
    let url = `v2/organizations`;
    if (searchTerm) url += `?companyName=${searchTerm}&code=${searchTerm}`;
    return firstValueFrom(this.http.get<Page<UserOrganization>>(url).pipe(map((res) => res.content)));
  }

  public async getOrganizationClusters(id): Promise<OrganizationCluster[]> {
    return firstValueFrom(this.http.get<OrganizationCluster[]>(`v2/organizations/${id}/clusters`));
  }

  public async getOrganizationUsers(id, page = 0, size = 10, searchTerm = ''): Promise<Object> {
    let url = `v2/organizations/${id}/users?page=${page}&size=${size}`;
    if (searchTerm) url += `&email=${searchTerm}`;
    return firstValueFrom(this.http.get(url));
  }

  public async getOrganizationPushLog(id): Promise<Object> {
    return firstValueFrom(this.http.get(`v2/organizations/${id}/push-log`));
  }

  public async setOrganizationPushLog(id, data): Promise<Object> {
    return firstValueFrom(this.http.put(`v2/organizations/${id}/push-log`, data));
  }

  public async deleteOrganizationPushLog(id): Promise<void> {
    await firstValueFrom(this.http.delete(`v2/organizations/${id}/push-log`));
    this.currentOrganization.pushLog = null;
  }

  public changeCurrentOrganization(organizationId: number): void {
    const user = this.getUser();
    // saved only immutable user ACL
    user.datasFonctionnalite = user.datasFonctionnalite.filter((f) =>
      [SHOW_ADMIN, ACL_CRON, DEPRECATED_ACL_PROP_CLUSTER].includes(f.code),
    );
    this.setUser(user);
    this.setCacheOrganizationId(user.id, organizationId);
    window.location.reload();
  }

  public setCacheOrganizationId(userId, organizationId): void {
    localStorage.setItem(MAP_USER_TO_FAVORITE_ORGANIZATION_KEY_SESSION_STORAGE(userId), organizationId);
  }

  public getCacheOrganizationId(userId): Object {
    return JSON.parse(localStorage.getItem(MAP_USER_TO_FAVORITE_ORGANIZATION_KEY_SESSION_STORAGE(userId)));
  }

  public purgeContext(): void {
    this.user = null;
    this.organizations = [];
    this.currentOrganization = null;
  }

  public purgeSession(): void {
    sessionStorage.clear();
  }

  public canEdit(site) {
    return site.accessRights.includes(ACL_SITE_ACTIONS.EDIT);
  }

  public canShare(site) {
    return site.accessRights.includes(ACL_SITE_ACTIONS.SHARE);
  }

  public canDelete(site) {
    return site.accessRights.includes(ACL_SITE_ACTIONS.DELETE);
  }

  public canDoMoreThanView(site) {
    return site.accessRights.find((access) => access != ACL_SITE_ACTIONS.VIEW);
  }
}

export type LoginResponse = LoginSuccess | MfaRequired;

export type LoginSuccess = {
  mfa: MFA.NONE;
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  lang: Lang;
  profilCode: string;
  datasFonctionnalite: Fonctionnalite[];
  hasApiKey: boolean;
};

export type MfaRequired = {
  mfa: Exclude<MFA, MFA.NONE>;
  token: string;
};

export enum MFA {
  NONE = 'none',
  EMAIL = 'email',
  TOTP = 'totp',
}

export type ValidateRegistrationTokenResponse = {
  email: string;
  lastName: string;
  firstName: string;
  lang: Lang;
  mobile: string;
  mfa: MFA;
};

export type RegistrationRequest = {
  token: string;
  email: string;
  lastName: string;
  firstName: string;
  lang: Lang;
  mobile: string;
  password: string;
  mfa: MFA;
};
