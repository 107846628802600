<div class="card with-tabs">
  <form #profileForm="ngForm">
    <div class="col-md-12">
      <div class="row" style="padding-top: 15px">
        <div class="col-md-8 mt-2">
          <h4 [innerHTML]="'PersonalInformations' | translate"></h4>
        </div>
        <div class="col-md-4">
          <button
            type="button"
            (click)="updateUser()"
            [hidden]="!editField"
            class="btn btn-primary btn-sm pull-right"
            [innerHTML]="'Enregistrer' | translate"
          ></button>
          <button
            type="button"
            (click)="cancelEditUser()"
            [hidden]="!editField"
            class="btn btn-danger btn-sm pull-right mr-2"
            [innerHTML]="'Annuler' | translate"
          ></button>
          <button
            type="button"
            (click)="onEditField()"
            [hidden]="editField"
            class="btn btn-primary btn-sm pull-right"
            [innerHTML]="'Modifier' | translate"
          ></button>
        </div>
      </div>
      <hr />
      <div class="row" *ngIf="user">
        <div class="col-md-12">
          <div class="form-group row">
            <app-label class="col" label="Nom" />
            <div class="col-md-6">
              <input type="text" [(ngModel)]="user.lastName" name="nom" [disabled]="!editField" class="form-control" />
            </div>
          </div>
          <div class="form-group row">
            <app-label class="col" label="Prenom" />
            <div class="col-md-6">
              <input
                type="text"
                [(ngModel)]="user.firstName"
                name="prenoms"
                [disabled]="!editField"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col col-form-label" [innerHTML]="'AdresseMail' | translate"></label>
            <div class="col-md-6">
              <input type="text" [(ngModel)]="user.email" name="email" [disabled]="!editField" class="form-control" />
            </div>
          </div>
          <div class="form-group row">
            <app-label class="col" label="DefautLanguage" />
            <div class="col-md-6">
              <select [(ngModel)]="user.lang" name="lang" [disabled]="!editField" class="form-control">
                <option value="fr">{{ 'FR' | translate }}</option>
                <option value="en">{{ 'EN' | translate }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
