import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { CLUSTER_DELEGATION_ADMIN } from '../services/auth.service';
import { map } from 'rxjs/operators';
import { Cluster, ClusterRole, OrganizationClusterService } from '../services/organization-cluster.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminClustersResolver implements Resolve<ClusterWithRole[]> {
  constructor(private organizationClusterService: OrganizationClusterService) {}

  resolve(): Observable<ClusterWithRole[]> {
    return this.organizationClusterService.getClusters().pipe(
      map((clusters) =>
        clusters
          .filter((c) => c.role == 'ADMIN' || c.role == 'OWNER')
          .map((organizationCluster) => ({
            ...organizationCluster.cluster,
            role: organizationCluster.role,
          })),
      ),
    );
  }
}

export type ClusterWithRole = Cluster & { role: ClusterRole };
