import { TranslateService } from '@ngx-translate/core';
import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ADMIN_CONTEXT, ContextEnum, Site, SitesService } from 'app/services/sites.service';
import { Subject, debounceTime } from 'rxjs';
import { copyObject, SECOND } from '../utils/data-utils';
import _ from 'lodash';
import { DateRange } from '../utils/date-range';
import { RangePreset } from '../date-range-selector/calendar-data.service';

const REFRESH_DURATION = 60 * SECOND;
const COUNTDOWN_STEP_DURATION = 1 * SECOND;

@Component({
  selector: 'app-site-selector',
  templateUrl: './site-selector.component.html',
  styleUrls: ['./site-selector.component.scss', '../../../assets/icon/icofont/css/icofont.scss'],
  standalone: false,
})
export class SiteSelectorComponent implements OnDestroy {
  @Input() ctx: ContextEnum;
  @Input() showTags = true;
  @Input() canSelectAllClusters = false;
  @Input() showDatePicker = true;
  @Input() canAutoRefresh = false;
  @Input() isLoading = false;
  @Input() datePickerSlug = ''; // have to pass a different HTML id to each date range picker between each page, otherwise it's buggy
  @Input() minDateDays = 90;
  @Input() ranges: RangePreset[] = ['15M', '1H', '4H', 'T', 'Y', '7d', '30d', '90d'];
  @Output() refresh = new EventEmitter();
  @Output() selectionChange = new EventEmitter<SiteSelection>();
  @Output() onPeriodEmitted = new EventEmitter<DateRange>();

  refreshDuration = REFRESH_DURATION;
  refreshInterval: any = null;
  countdownProgressInterval: any = null;
  countNextRefresh = 0;
  countSteps = this.refreshDuration / COUNTDOWN_STEP_DURATION;

  private debounceSubject: Subject<SiteSelection> = new Subject();

  public ADMIN_CONTEXT = ADMIN_CONTEXT;

  constructor(
    public translate: TranslateService,
    public sites: SitesService,
  ) {
    this.debounceSubject.pipe(debounceTime(200)).subscribe((selection) => {
      this.selectionChange.emit();
    });
  }

  ngOnDestroy(): void {
    this.clearRefreshInterval();
  }

  setRefreshInterval() {
    if (this.refreshInterval) {
      return;
    }

    this.countNextRefresh = 0;
    this.countdownProgressInterval = setInterval(() => this.countNextRefresh++, COUNTDOWN_STEP_DURATION);
    this.refreshInterval = setInterval(() => this.onRefresh(), REFRESH_DURATION + COUNTDOWN_STEP_DURATION);
  }

  clearRefreshInterval() {
    if (!this.refreshInterval) {
      return;
    }

    clearInterval(this.countdownProgressInterval);
    clearInterval(this.refreshInterval);
    this.refreshInterval = null;
    this.countNextRefresh = 0;
  }

  onRefresh() {
    this.clearRefreshInterval();
    this.refresh.emit();
  }

  onDataLoaded() {
    if (this.sites[this.ctx].current.period.autoRefresh && this.canAutoRefresh) this.setRefreshInterval();
  }

  refreshSiteList(event) {
    this.sites.computeSiteList(this.ctx);
    this.hostChange(event);
  }

  hostChange(event) {
    this.clearRefreshInterval();
    this.debounceSubject.next({
      clusterId: this.sites[this.ctx].current.cluster?.clusterId,
      sites: this.sites[this.ctx].current.selectedSites?.map((site) => site.name),
      ...(this.showTags ? { tags: this.sites[this.ctx].current.tag ? [this.sites[this.ctx].current.tag] : [] } : {}),
    });
  }

  rangeChange(range: DateRange) {
    this.clearRefreshInterval();
    this.onPeriodEmitted.emit(range);
  }

  protected readonly Math = Math;
}

type SiteSelection = {
  clusterId: string;
  sites: string[];
  tags?: string[];
};
