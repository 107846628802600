<div *ngIf="site" class="card with-tabs p-3">
  <div *ngIf="disabled" class="bg-blue mb-4" style="margin-top: -10px">
    {{ 'SubscribeToAdvanced' | translate }}
  </div>
  <h5 class="flex-align-items">
    {{ 'Cache' | translate }}
    <app-help [url]="'ExternalHelp.cache' | translate" />
  </h5>
  <hr />
  <div [ngStyle]="{ opacity: disabled ? '0.65' : '1' }">
    <div class="row form-group">
      <label class="col col-form-label">
        {{ 'CacheDesc' | translate }}
        <a class="ogo-link" target="_blank" [href]="EXTERNAL_RFC_7234_CACHE_LINK"
          >RFC 7234<i class="icofont icofont-external-link ml-1"></i
        ></a>
      </label>
      <app-switch class="col-md-auto" [(ngModel)]="cacheEnabled" [disabled]="disabled" [click]="toggleCache" />
    </div>
    <div id="purge-row" class="row form-group">
      <label class="col col-form-label">{{ 'PurgeCache' | translate }}</label>
      <div class="col-xl-6">
        <div class="flex-align-items">
          <input
            [(ngModel)]="purgeRegexp"
            [disabled]="!cacheEnabled || disabled"
            class="form-control"
            placeholder="/static/.*"
          />
          <button
            class="btn btn-primary btn-sm"
            [disabled]="!cacheEnabled || disabled || !purgeRegexp?.trim()"
            (click)="purge()"
          >
            {{ 'Purge' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="showCdn">
    <h5 class="flex-align-items">CDN Edge</h5>
    <hr />
    @if (site.contract?.cdnEnabled) {
      <div [ngStyle]="{ opacity: disabled ? '0.65' : '1' }">
        <div class="row form-group">
          <label
            class="col col-form-label cdn-label"
            [innerHTML]="'CDN.enableDescription' | translate: { cdnLogo: orangeCdb }"
          ></label>
          <app-switch
            class="col-md-auto"
            [(ngModel)]="cdnEnabled"
            [disabled]="cdnNotAvailableReason.length > 0"
            [click]="toggleCdn"
          />
        </div>
        <div *ngIf="cdnNotAvailableReason.length > 0" class="bg-orange">
          <p>{{ 'CDN.cannotEnable.title' | translate }}</p>
          <ul>
            <li *ngFor="let reason of cdnNotAvailableReason">{{ reason | translate }}</li>
          </ul>
        </div>
        <div *ngIf="site.cdn" class="row form-group status">
          <div class="col">
            {{ 'CDN.status.label' | translate }}
            <span *ngIf="cdnEnabled" class="label cdn" [ngClass]="site.cdnStatus"
              ><span
                *ngIf="['ACTIVATION_IN_PROGRESS', 'SYNC_IN_PROGRESS'].includes(site.cdnStatus)"
                class="spinner"
              ></span>
              {{ 'CDN.status.' + site.cdnStatus | translate }}</span
            >
          </div>
        </div>
        <div *ngIf="site.cdnStatus != 'ACTIVATION_IN_PROGRESS'" class="row">
          <p *ngIf="site.cluster?.cdnCname" class="col">
            {{ 'BasculeDns.cdn' | translate }} <strong>{{ site.cluster?.cdnCname }}</strong>
            <span class="badge badge-sm bg-dark ml-1" [ngbTooltip]="cdnNameHelp" tooltipClass="cdn-tooltip">?</span>
          </p>
          <ng-template #cdnNameHelp>
            <p>
              {{ 'BasculeDns.cdnTooltip.cdn' | translate }}<br />
              <span class="dns-record">{{ site.name }}. IN CNAME {{ site.cluster?.cdnCname }}.</span>
            </p>
            <p>
              <span
                [innerHtml]="
                  'BasculeDns.cdnTooltip.cluster' | translate: { cluster: '<em>' + site.cluster?.name + '</em>' }
                "
              ></span
              ><br />
              <span class="dns-record">{{ site.name }}. IN CNAME {{ site.cluster.ipv6 ?? site.cluster.ip }}.</span>
            </p>
          </ng-template>
        </div>
      </div>
    } @else {
      <p [innerHTML]="'CDN.notEnabledInContract' | translate"></p>
    }
  </ng-container>
</div>
