import { FeatureFlags } from './feature-flags';
import { InjectionToken, StaticProvider } from '@angular/core';

export interface Config {
  featureFlags: Partial<FeatureFlags>;
  reloadOnError: boolean;
  silentConsole: boolean;
  detectVersionChange: boolean;
  enableGrooveHQ: boolean;
}

export const CONFIG = new InjectionToken<Config>('config');

export function fetchConfig(): Promise<Config> {
  return fetch('assets/config.json').then((res) => res.json());
}

export function provideConfig(config: Config): StaticProvider {
  return { provide: CONFIG, useValue: config };
}
