import { SitesService } from 'app/services/sites.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { destroyGrooveHQWidget } from '../groovehq';
import { DASHBOARD_ROUTE } from '../app-routing.module';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private sites: SitesService,
  ) {}

  // Auth

  async connectAsUser(userId: number, organizationId: number) {
    this.http
      .post('utilisateur/connectAs', {
        data: { id: userId },
      })
      .subscribe((res: any) => {
        this.auth.purgeContext();
        this.auth.purgeSession();
        this.sites.purgeContext();
        this.auth.setCacheOrganizationId(userId, organizationId);
        this.auth.setUser(res.item);
        destroyGrooveHQWidget();
        window.location.href = `/#/${DASHBOARD_ROUTE}`;
        window.location.reload();
      });
  }
}
