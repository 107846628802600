import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import { HostModeService } from '../../../shared/hostMode/hostMode.service';
import { AuthService } from 'app/services/auth.service';
import { EXTERNAL_RFC_7234_CACHE_LINK } from 'app/app-routing.module';
import { combineLatestWith, firstValueFrom, from, Observable } from 'rxjs';
import { OrganizationSiteService } from '../../../services/organization-site.service';
import { Site } from 'app/services/entities/site';
import { confirm } from '../../../shared/utils/confirm';

const PURGE_REGEXP = 'purge-regexp';
const ORANGE = 'ORANGE';

@Component({
  selector: 'app-cache-cdn',
  templateUrl: './cache-cdn.component.html',
  styleUrls: [
    './cache-cdn.component.scss',
    '../../my-sites/site-status.scss',
    '../../../../assets/icon/icofont/css/icofont.scss',
    '../add-site.component.scss',
  ],
  standalone: false,
})
export class CacheCdnComponent implements OnInit {
  @Input({ required: true }) siteName: string;
  @Input({ required: true }) showCdn: boolean;
  @Output() cacheEnabledChange = new EventEmitter<boolean>();

  ruleUseCache: boolean;

  EXTERNAL_RFC_7234_CACHE_LINK = EXTERNAL_RFC_7234_CACHE_LINK;
  orangeCdb =
    '<span class="orange-cdb"><span class="cdb">Content Delivery Boost</span> by <img src="/assets/images/orange.svg" aria-label="orange"></span>';

  site: Site;
  disabled = true;
  purgeRegexp = '';
  cacheEnabled: boolean;
  cdnEnabled: boolean;
  hasActiveCertificate: boolean;
  cdnNotAvailableReason: string[];

  constructor(
    private siteApi: OrganizationSiteService,
    private http: HttpClient,
    private toastr: ToastrService,
    private translate: TranslateService,
    private auth: AuthService,
    public hostModeService: HostModeService,
  ) {}

  ngOnInit(): void {
    this.purgeRegexp = localStorage.getItem(PURGE_REGEXP);
    this.siteApi
      .getSite(this.siteName)
      .pipe(combineLatestWith(this.siteApi.getCertificates(this.siteName), this.siteApi.getRules(this.siteName)))
      .subscribe({
        next: ([site, certificates, rules]) => {
          this.hasActiveCertificate = certificates.some((cert) => cert.active);
          this.ruleUseCache = rules.some((rule) => rule.cache);
          this.setSite(site);
        },
        error: (error) => {
          this.toastr.error(`Failed to load site: ${error}`);
        },
      });
  }

  setSite(site: Site) {
    this.site = site;
    this.disabled = !this.hostModeService.isAdvanced(this.site.mode);
    this.cacheEnabled = site.cacheEnabled;
    this.cdnEnabled = !!site.cdn;
    const isApex = site.name.split('.').length == 2;
    this.cdnNotAvailableReason = [];
    if (!this.cacheEnabled) this.cdnNotAvailableReason.push('CDN.cannotEnable.Cache is not enabled');
    if (isApex) this.cdnNotAvailableReason.push('CDN.cannotEnable.Site is APEX');
    if (!this.hasActiveCertificate)
      this.cdnNotAvailableReason.push('CDN.cannotEnable.Site does not have an active certificate');
  }

  toggleCache = (): Observable<boolean> => {
    if (this.disabled) return from([false]);

    const checked = !this.cacheEnabled;

    if (!checked && this.ruleUseCache) {
      this.toastr.error(this.translate.instant('RuleUseCache'));
      return from([false]);
    }

    return confirm(
      {
        title: this.translate.instant('Confirmation'),
        html: this.translate.instant(checked ? 'EnableCache' : 'DisableCache'),
        ...this.alertOptions,
      },
      () =>
        this.siteApi.patchSite(this.site.id, { cacheEnabled: checked }).subscribe((updatedSite) => {
          this.setSite(updatedSite);
          this.cacheEnabledChange.emit(updatedSite.cacheEnabled);
          this.toastr.success(this.translate.instant('OperationSuccess'));
        }),
    );
  };

  toggleCdn = () => {
    const checked = !this.cdnEnabled;

    return confirm(
      {
        title: this.translate.instant('Confirmation'),
        html: this.translate.instant(checked ? 'CDN.confirmation.enable' : 'CDN.confirmation.disable'),
        ...this.alertOptions,
      },
      () =>
        this.siteApi.patchSite(this.site.id, { cdn: checked ? ORANGE : null }).subscribe((updatedSite) => {
          this.setSite(updatedSite);
          this.toastr.success(this.translate.instant('OperationSuccess'));
        }),
    );
  };

  async purge() {
    localStorage.setItem(PURGE_REGEXP, this.purgeRegexp);
    await firstValueFrom(
      this.http.post(`v2/organizations/${this.auth.currentOrganization.id}/sites/${this.site.name}/purgeCache`, {
        path: this.purgeRegexp,
      }),
    );
    this.toastr.success(this.translate.instant('OperationSuccess'));
  }

  alertOptions = {
    showCancelButton: true,
    confirmButtonColor: '#4099ff',
    cancelButtonColor: '#d33',
    cancelButtonText: this.translate.instant('No'),
    confirmButtonText: this.translate.instant('YesConfirm'),
  };
}
