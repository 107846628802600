import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import { AuthService } from '../../services/auth.service';
import { map } from 'rxjs/operators';
import { OgoResponseItems } from '../../services/ogo-response';
import { Cluster } from '../../services/organization-cluster.service';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss', '../../../assets/icon/icofont/css/icofont.scss'],
  standalone: false,
})
export class MaintenanceComponent implements OnInit {
  user: any = {};
  activeTab = 'logExport';
  clusters: Cluster[] = [];
  cluster: Cluster;

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.user = this.auth.getUser();

    this.route.data.subscribe((data: { clusters: any }) => {
      this.clusters = data.clusters;
      this.clusterSelected(this.clusters[0]);
    });
  }

  togglePushLog = () => {
    return this.http
      .post<OgoResponseItems<any>>('cluster/setPushLog', {
        data: { id: this.cluster.id, logExport: !this.cluster.logExport },
      })
      .pipe(
        map((res) => {
          if (!res.hasError) {
            this.cluster = res.items[0];
            this.toastr.success(this.translate.instant('OperationSuccess'));
            return true;
          } else {
            this.toastr.error(res.status?.message);
            return false;
          }
        }),
      );
  };

  clusterSelected(cluster: Cluster) {
    this.cluster = cluster;
  }
}
