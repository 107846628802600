<app-site-selector
  #siteSelector
  [ctx]="ctx"
  (selectionChange)="refresh()"
  [showTags]="ctx != ADMIN_CONTEXT"
  [canAutoRefresh]="true"
  [minDateDays]="30"
  [ranges]="['15M', '1H', '4H', 'T', 'Y', '7d', '30d']"
  [isLoading]="isLoading"
  (refresh)="refresh()"
  (onPeriodEmitted)="onPeriodEmitted($event)"
  [datePickerSlug]="'dashboard'"
/>

<div *ngIf="!isNewOrga" class="row" class="to-right">
  <a *ngIf="isPrintable; else noPrint" class="ogo-link" (click)="toPrint()">{{ 'PrintThisReport' | translate }}</a>
  <ng-template #noPrint>&nbsp;</ng-template>
</div>

<div *ngIf="isNewOrga" class="bg-blue new-site">
  {{ 'WelcomeOgo' | translate }}&nbsp;
  <a [routerLink]="['/' + MY_SITES_ROUTE + '/' + ADD_ROUTE]" class="new-site-link">{{ 'AjouterUnSite' | translate }}</a>
  {{ 'ToStart' | translate }}
</div>

<div class="row">
  <app-dashboard-card
    class="col-md-6 col-xl-3"
    bgColor="bg-c-pink"
    [title]="'Bloquees' | translate"
    [action]="ActionEnum.BLOCKED"
    [totalKey]="BLOCKED_BY_BRAIN"
    (trackAction)="trackAction($event)"
    (selectSite)="selectSite($event)"
    [totals]="totals"
    [breakdown]="breakdown"
    [isLoading]="isLoading"
  >
  </app-dashboard-card>

  <app-dashboard-card
    class="col-md-6 col-xl-3"
    bgColor="bg-c-suspect"
    [title]="'Suspectes' | translate"
    [action]="ActionEnum.SUSPICIOUS"
    [totalKey]="SUSPECT"
    (trackAction)="trackAction($event)"
    (selectSite)="selectSite($event)"
    [totals]="totals"
    [breakdown]="breakdown"
    [isLoading]="isLoading"
  >
  </app-dashboard-card>

  <app-dashboard-card
    class="col-md-6 col-xl-3"
    bgColor="bg-c-robot"
    [title]="'Robots' | translate"
    [action]=""
    [totalKey]="ROBOT"
    (trackAction)="trackAction($event)"
    (selectSite)="selectSite($event)"
    [totals]="totals"
    [breakdown]="breakdown"
    [isLoading]="isLoading"
  >
  </app-dashboard-card>

  <app-dashboard-card
    class="col-md-6 col-xl-3"
    bgColor="bg-c-green"
    [title]="'Analysees' | translate"
    [action]=""
    [totalKey]="ANALYZED"
    (trackAction)="trackAction($event)"
    (selectSite)="selectSite($event)"
    [totals]="totals"
    [breakdown]="breakdown"
    [isLoading]="isLoading"
  >
  </app-dashboard-card>

  <div id="attacks-donut" class="col-xl-6">
    <div class="card" style="min-height: 400px">
      <div *ngIf="isLoading" class="theme-loader flex">
        <div class="loader-track">
          <div class="loader-bar"></div>
        </div>
      </div>
      <app-donut
        *ngIf="!isLoading && blockAttack.length != 0; else !isLoading && emptyAttacks"
        [data]="blockAttack"
        [title]="translate.instant('TypesAttaques')"
        (sliceClicked)="onAttackCategoryClick($event)"
      />
      <ng-template #emptyAttacks>
        <p class="graph-title-no-data text-center mt-3">{{ 'TypesAttaques' | translate }}</p>
        <p class="text-center" style="margin-top: 140px">{{ 'NoData' | translate }}</p>
      </ng-template>
    </div>
  </div>

  <div id="trafic-graph" class="col-xl-6">
    <div class="card" style="min-height: 400px">
      <div *ngIf="isLoading" class="theme-loader flex">
        <div class="loader-track">
          <div class="loader-bar"></div>
        </div>
      </div>
      <span *ngIf="!isLoading && totals[TRAFIC] != 0 && chartInterval" class="chart-interval"
        >Interval : {{ chartInterval | durationSeconds }}</span
      >
      <app-graph
        *ngIf="!isLoading && totals[TRAFIC] != 0; else !isLoading && emptyTrafic"
        [exportButtonSpacing]="{ x: 20, y: -30 }"
        [data]="audience"
        [cursor]="'default'"
        (zoneSelected)="onPeriodEmittedFromZoneSelected($event)"
      ></app-graph>
      <ng-template #emptyTrafic>
        <p class="graph-title-no-data text-center mt-3">{{ 'AnalyseTrafic' | translate }}</p>
        <p class="text-center" style="margin-top: 140px">{{ 'NoData' | translate }}</p>
      </ng-template>
    </div>
  </div>

  <div class="col-md-12 world-map">
    <div class="card">
      <div class="btn-group" role="group">
        <button
          (click)="changeOrigine(TRAFIC)"
          type="button"
          class="btn btn-square"
          [class.btn-inverse]="originSelected == TRAFIC"
        >
          {{ 'Requests' | translate }}
        </button>
        <button
          (click)="changeOrigine(ROBOT)"
          type="button"
          class="btn btn-square"
          [class.btn-inverse]="originSelected == ROBOT"
        >
          {{ 'Robots' | translate }}
        </button>
        <button
          (click)="changeOrigine(BLOCKED)"
          type="button"
          class="btn btn-square"
          [class.btn-inverse]="originSelected == BLOCKED"
        >
          {{ 'RequetesBloqueesByAI' | translate }}
        </button>
        <button
          (click)="changeOrigine(BLOCKED_COUNTRY)"
          type="button"
          class="btn btn-square"
          [class.btn-inverse]="originSelected == BLOCKED_COUNTRY"
        >
          {{ 'Blocked Countries' | translate }}
        </button>
      </div>
      <app-world-map
        [origine]="originSelected"
        [data]="pays ? pays[originSelected] : null"
        [height]="'700px'"
        (countryClick)="onCountryClick($event)"
        class="app-world-map"
      />
    </div>
  </div>

  <div class="col-md-12" *ngIf="isAdminClusterView ? isASiteSelected : true">
    <div
      class="btn-group tabs-over-graph tabs-dashboard-tables"
      data-original-title=".btn-xlg"
      data-placement="top"
      data-toggle="tooltip"
      role="group"
      title=""
    >
      <button
        (click)="selectTable(Table.TOP_BLOCKED_IPS)"
        class="btn waves-effect waves-light"
        [ngClass]="{ 'btn-inverse': pageSelected == Table.TOP_BLOCKED_IPS }"
        type="button"
        [innerHTML]="'topBlockedIps' | translate"
      ></button>
      <button
        (click)="selectTable(Table.SLOW_REQUESTS)"
        class="btn waves-effect waves-light"
        [ngClass]="{ 'btn-inverse': pageSelected == Table.SLOW_REQUESTS }"
        type="button"
        [innerHTML]="'RequetesLentes' | translate"
      ></button>
      <button
        (click)="selectTable(Table.CPU_CONSUMING_REQUESTS)"
        class="btn waves-effect waves-light"
        [ngClass]="{ 'btn-inverse': pageSelected == Table.CPU_CONSUMING_REQUESTS }"
        type="button"
        [innerHTML]="'PagesPlusVisitees' | translate"
      ></button>
    </div>
    <div class="card with-tabs">
      <div *ngIf="!isTableLoading; else loader">
        <ng-container [ngSwitch]="pageSelected">
          <ng-container *ngSwitchCase="Table.TOP_BLOCKED_IPS">
            <app-blocked-ips-table
              [topBlockedIps]="topBlockedIps"
              (clickCountry)="navigateToLogs($event)"
              (clickIp)="navigateToLogs($event)"
              (whitelistIp)="addWhitelistedIp($event)"
            />
          </ng-container>

          <ng-container *ngSwitchCase="Table.SLOW_REQUESTS">
            <app-slow-requests-table [slowRequests]="slowRequests" (clickPath)="navigateToLogs($event)" />
          </ng-container>

          <ng-container *ngSwitchCase="Table.CPU_CONSUMING_REQUESTS">
            <app-most-frequent-requests-table
              [cpuConsumingRequests]="cpuConsumingRequests"
              (clickPath)="navigateToLogs($event)"
            />
          </ng-container>
        </ng-container>
      </div>
      <ng-template #loader>
        <div class="component-loader">
          <div class="loader-track">
            <div class="loader-bar"></div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
