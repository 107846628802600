import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiDocService {
  constructor(private http: HttpClient) {}

  getApiDoc() {
    return this.http.get(`v2/api-doc`);
  }
}
