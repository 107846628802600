<div class="container-fluid">
  <app-cluster-selector
    [cluster]="cluster"
    [clusters]="clusters"
    (selected)="clusterSelected($event)"
  ></app-cluster-selector>

  <app-tabs>
    <app-tab [tabTitle]="'LogExport' | translate" [active]="activeTab == 'logExport'">
      <div class="card">
        <div class="col-md-12">
          <div class="row" style="padding-top: 15px">
            <div class="col-md-12 mt-2">
              <h4 class="mb-0">{{ 'LogExport' | translate }}</h4>
            </div>
          </div>
          <hr />
          <div class="form-group flex-align-items space-between">
            <div>
              <label>{{ 'PushLogRouteCluster' | translate }}</label
              >&nbsp;
            </div>
            <app-switch [ngModel]="cluster.logExport" [click]="togglePushLog"></app-switch>
          </div>
        </div>
      </div>
    </app-tab>
  </app-tabs>
</div>
