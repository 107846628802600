/// <reference types="@angular/localize" />
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { fetchConfig, provideConfig } from './config/config';

(async function () {
  const config = await fetchConfig();
  if (config.silentConsole && window) {
    window.console.log = window.console.warn = window.console.info = function () {};
  }

  platformBrowserDynamic([provideConfig(config)])
    .bootstrapModule(AppModule)
    .catch((err) => console.log(err));
})();
