import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Cluster } from '../../../services/sites.service';

@Component({
  selector: 'app-cluster-selector',
  templateUrl: './cluster-selector.component.html',
  styleUrls: ['./cluster-selector.component.scss', '../../../../assets/icon/icofont/css/icofont.scss'],
  standalone: false,
})
export class ClusterSelectorComponent<T extends Cluster> {
  @Input() clusters: T[];
  @Input() cluster: T;
  @Output() selected = new EventEmitter<T>();

  selectCluster(cluster: T) {
    return this.selected.emit(cluster);
  }
}
