import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Cluster, Site } from '../../../services/entities/site';
import { HostModeService } from '../../../shared/hostMode/hostMode.service';
import { validateDomainName } from '../../../shared/validators/validators';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ACL_PUSH_LOG_ADD_SITE, ACL_SITE_ACTIONS, AuthService } from '../../../services/auth.service';
import { ToastrService } from '../../../shared/toastr/toastr.service';
import { TranslateService } from '@ngx-translate/core';
import { SitesService } from '../../../services/sites.service';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import _ from 'lodash';

import { EditMode } from 'app/shared/edit.mode';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationCluster } from '../../../services/organization-cluster.service';
import { OgoResponseItems } from '../../../services/ogo-response';

@Component({
  selector: 'app-general-config',
  standalone: false,

  templateUrl: './general-config.component.html',
  styleUrls: ['./general-config.component.scss', '../../../../assets/icon/icofont/css/icofont.scss'],
})
export class GeneralConfigComponent implements OnInit, AfterViewInit {
  @Input({ required: true }) site: Partial<Site> = {};
  @Input({ required: true }) mode: EditMode;
  @Input({ required: true }) goBack: Function;

  @ViewChild('domainName') domainName: ElementRef;
  @ViewChild('hostMode') siteMode: ElementRef;

  clusters: OrganizationCluster[] = [];
  cluster: Cluster;
  https: HttpsEnum;
  urlParam: any = {};
  provisioningInProgress: boolean = false;
  tags = [];
  diagnoseResult: DiagResult;
  diagnoseHttps = false;
  canSetMode: boolean = false;
  canSetPushLog: boolean = false;

  protected readonly EditMode = EditMode;

  constructor(
    protected location: Location,
    private http: HttpClient,
    public auth: AuthService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private sites: SitesService,
    public hostModeService: HostModeService,
  ) {
    this.clusters = this.auth.currentOrganization.clusters;
  }

  async ngOnInit() {
    this.getTags();
    if (!this.site.name) {
      this.mode = EditMode.CREATE;
      this.site = {
        destHostScheme: 'https',
        dryRun: false,
        forceHttps: true,
        hsts: 'none',
        noCopyXForwardedFor: false,
        trustSelfSigned: true,
        panicMode: false,
      };
      this.setCluster(this.clusters[0]);
      this.clusterChange();
      this.showSite();
    } else {
      this.mode = EditMode.UPDATE;
      this.cluster = this.site.cluster;
      const organizationCluster = this.clusters.find((c) => c.cluster.clusterId == this.site.cluster.clusterId);
      this.canSetMode = organizationCluster && ['ADMIN', 'OWNER'].includes(organizationCluster?.role);
      this.showSite();
    }
  }

  ngAfterViewInit() {
    if (this.mode == EditMode.CREATE) {
      fromEvent(this.domainName.nativeElement, 'keyup')
        .pipe(debounceTime(1000))
        .subscribe((str: any) => {
          this.urlParam = {};
          this.checkDnsHandler(str.target.value);
        });
    }
  }

  private setCluster(cluster: OrganizationCluster) {
    this.cluster = cluster.cluster;
    this.canSetMode = ['ADMIN', 'OWNER'].includes(cluster?.role);
  }

  clusterChange() {
    this.site.mode = this.cluster.defaultSiteMode;
  }

  async showSite() {
    this.urlParam = {};
    if (!this.site.forceHttps) {
      this.https = 'http';
    } else {
      this.https = this.site.hsts == 'none' ? 'https' : this.site.hsts;
    }

    this.canSetPushLog =
      this.auth.currentOrganization.pushLog &&
      (this.mode === EditMode.UPDATE
        ? this.siteHasAccessRight(ACL_SITE_ACTIONS.PUSH_LOG_SITE)
        : this.auth.userHasFeature(ACL_PUSH_LOG_ADD_SITE));

    this.checkDns(this.site.name);
  }

  async createSite() {
    this.provisioningInProgress = true;

    this.http.post('hote/create', { datas: [this.getPayload()] }).subscribe(
      (res: any) => {
        if (!res.hasError) {
          this.toastr.success(this.translate.instant('SiteSuccesfullyAdded'));
          this.sites.purgeContext();
          this.goBack();
          return;
        } else {
          this.toastr.error(this.translate.instant(res.status.message));
          return;
        }
      },
      (err: any) => {
        console.error('Error on createHost : ', err);
      },
      () => {
        this.restoreDefault();
      },
    );
  }

  updateSite() {
    this.provisioningInProgress = true;

    this.http
      .post('hote/update', {
        datas: [this.getPayload()],
      })
      .subscribe(
        (res: any) => {
          if (!res.hasError && res.items && res.items[0]) {
            this.toastr.success(this.translate.instant('SiteModifieSucc'));
            this.sites.purgeContext();
            this.goBack();
            return;
          } else if (res.status.code == '938') {
            this.siteMode.nativeElement.value = this.site.mode;
            this.toastr.error(res.status.message);
            return;
          } else {
            this.toastr.error(this.translate.instant(res.status.message));
            return;
          }
        },
        (err: any) => {
          console.error('Error on updateHost : ', err);
        },
        () => {
          this.restoreDefault();
        },
      );
  }

  private getPayload(): any {
    const payload: any = _.omit(
      this.site,
      'cluster',
      'mode',
      'logExport',
      'owner',
      'accessRights',
      'status',
      'createdAt',
      'updatedAt',
      'certificates',
      'exceptions',
      'rewriteRules',
      'rules',
      'blacklistedCountries',
      'whitelistedIps',
    );
    payload.destHost = payload.destHost.trim();
    payload.name = payload.name.trim().toLowerCase();
    if (this.mode == EditMode.CREATE) payload.clusterId = this.cluster.id;
    payload.forceHttps = this.https != 'http';
    payload.hsts = this.https == 'http' || this.https == 'https' ? 'none' : this.https;
    payload.port = payload.port ? payload.port : null;
    if (this.canSetMode) payload.mode = this.site.mode;
    if (this.canSetPushLog) payload.logExport = this.site.logExport;

    return payload;
  }

  restoreDefault() {
    this.provisioningInProgress = false;
  }

  validate() {
    const invalid =
      !this.site.destHost ||
      !this.site.name ||
      !this.cluster ||
      this.provisioningInProgress ||
      (!this.urlParam.ipv4Address && this.urlParam.ipv6Address);

    return invalid;
  }

  checkDnsHandler(domain = ''): boolean {
    if (domain === undefined) {
      return;
    }

    if (!validateDomainName(domain)) {
      this.toastr.info(this.translate.instant('AdressNotValid'));
      return;
    }

    this.checkDns(domain);
  }

  checkDns(p: string) {
    if (p == null) {
      return;
    }
    this.urlParam = {};
    this.http
      .post<OgoResponseItems<any>>('hote/resolve', {
        data: { nom: p.trim() },
      })
      .subscribe((res) => {
        if (!res.hasError) {
          this.urlParam = res.items[0];
          if (!Object.keys(this.urlParam).length) {
            this.urlParam.noRecord = true;
            return this.toastr.warning(this.translate.instant('NoRecord'));
          }
          if (this.mode === EditMode.CREATE) {
            this.site.destHost =
              this.urlParam.cname ||
              this.urlParam.ipv4Address ||
              (this.cluster.supportsIpv6Origins && this.urlParam.ipv6Address ? this.urlParam.ipv6Address : null);
          }
        } else {
          this.toastr.error(res.status.message);
        }
      });
  }

  onPasteDomain(event) {
    if (event.target.value.length == 0 || window.getSelection().toString() == event.target.value) {
      event.preventDefault();
      let paste = (event.clipboardData || window['clipboardData']).getData('text');
      paste = paste.trim();
      event.target.value = paste;
      this.site.name = paste;
    }
  }

  onPasteDestination(event) {
    if (event.target.value.length == 0 || window.getSelection().toString() == event.target.value) {
      event.preventDefault();
      let paste = (event.clipboardData || window['clipboardData']).getData('text');
      paste = paste.trim();
      if (paste.slice('-1') == '.') {
        event.target.value = paste.slice(0, -1);
      } else {
        event.target.value = paste;
      }
      this.site.destHost = event.target.value;
    }
  }

  diagnose() {
    this.diagnoseResult = null;
    this.diagnoseHttps = this.site.destHostScheme == 'https';
    this.http
      .post<DiagResult>('v2/sites/diagnose', {
        name: this.site.name,
        clusterId: this.cluster.id,
        destHost: this.site.destHost,
        port: this.site.port,
        destHostScheme: this.site.destHostScheme,
        trustSelfSigned: this.site.trustSelfSigned,
      })
      .subscribe({
        next: (res) => (this.diagnoseResult = res),
        error: () => this.toastr.error(this.translate.instant('OriginInvalid')),
      });
  }

  async getTags() {
    this.tags = await this.sites.loadTags();
  }

  siteHasAccessRight(right) {
    return this.site?.accessRights?.includes(right);
  }

  onTrustSelfSignedChange() {
    this.diagnoseResult = null;
  }
}

type HttpsEnum = 'http' | 'https' | 'hsts' | 'hstss' | 'hstssp';

interface DiagResult {
  IssueDetected: boolean;
  Backends: { [prop: string]: DiagBackend };
}

interface DiagBackend {
  ResolvStatus: DiagStatus;
  IPs: { [prop: string]: DiagIp };
}

interface DiagIp {
  HttpDiag: Diag;
  HttpsDiag: Diag;
  Http2Diag: Diag;
  TLSDiag: Diag;
}

interface Diag {
  Status: DiagStatus;
  Issues: string[];
}

type DiagStatus = 'OK' | 'KO' | 'NA';
